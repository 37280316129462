// PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

import { formalTexts } from '../data/Articles';
import { Body, Container, Header } from './AboutUs';
import useWindowSize from '../utils/hooks/useWindowSize';
import { helmet } from '../utils/config';

const PrivacyPolicy = () => {
    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <Container windowHeight={windowHeight}>
            <Helmet>
                <title>{helmet.PrivacyPolicyTitle}</title>
                <meta
                    name='description'
                    content={helmet.PrivacyPolicyDescription}
                />
                <link
                    rel='canonical'
                    href={'https://infoblis.com/privacy-policy'}
                />
                <meta
                    property='og:title'
                    content={helmet.PrivacyPolicyOGTitle}
                />
                <meta
                    property='og:description'
                    content={helmet.PrivacyPolicyOGDescription}
                />
                <meta name='keywords' content={helmet.PrivacyPolicyKeywords} />
                <script type='application/ld+json'>
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'PrivacyPolicy',
                        headline: helmet.PrivacyPolicyTitle,
                        description: helmet.PrivacyPolicyDescription,
                        url: 'https://infoblis.com/privacy-policy',
                        publisher: {
                            '@type': 'Organization',
                            name: 'infoblis',
                            logo: {
                                '@type': 'ImageObject',
                                url: helmet.LogoUrl,
                            },
                        },
                    })}
                </script>
            </Helmet>
            <Header windowWidth={windowWidth}>Privacy Policy</Header>
            <Body
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formalTexts.privacyPolicy),
                }}
            />
        </Container>
    );
};

export default PrivacyPolicy;
