import React, {
  // useCallback,
  // useEffect,
  // useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { mobileVersion } from "../data/positionsGrid";
// import { useFontSize } from '../utils/hooks/useFontSize';
import useWindowSize from "../utils/hooks/useWindowSize";
import { firebaseAnalyticsLogEvent } from "../../Firebase";
import { Link } from "react-router-dom";
import { formatArticlePath } from "../utils/pathUtils";

const getWidth = (props, type) => {
  return mobileVersion(props, "cardMinWidth")
    ? mobileVersion(props, "cardMinWidth")
    : "240px";
};

export const getHeight = (props, type) => {
  if (type === "card" && !props.isFeatured) {
    return mobileVersion(props, "cardHeight")
      ? mobileVersion(props, "cardHeight")
      : "300px";
  } else if (type === "image") {
    if (
      // searchResults 100% image height for cardTypes
      props.specialOrigin === "SearchResults" &&
      (props.cardType === "sideImage" || props.cardType === "textOnImage")
    ) {
      return "100%";
    }

    if (!props.isFeatured) {
      return props.cardType === "textOnImage"
        ? "100%"
        : mobileVersion(props, "imageHeight")
        ? mobileVersion(props, "imageHeight")
        : "195px";
    }
    if (props.cardType === "sideImage" || props.cardType === "textOnImage") {
      return "100%";
    }
  }
};
const CardLink = styled(
  ({ cardWidth, cardWidthNotDependentOnTitle, ...props }) => <Link {...props} />
)`
  display: inline-flex;
  flex-direction: column;
  width: ${(props) => (props.cardWidth ? props.cardWidth : "100%")};
  // background: green;
`;

const Card = styled.div`
  border: none;
  border-radius: 9px;
  background: white;
  display: flex;
  flex-direction: ${(props) =>
    props.cardType === "sideImage" &&
    (props.isFeatured || props.specialOrigin === "SearchResults")
      ? "row"
      : "column"};
  align-items: center;
  min-width: ${(props) =>
    props.cardType === "textOnImage" && !props.isFeatured
      ? props.minCardWidth
      : props.cardWidth || getWidth(props, "card")};
  height: ${(props) => props.cardHeight || getHeight(props, "card")};
  position: ${(props) =>
    props.isFeatured &&
    [
      "StartPage",
      "QuizMenuPage",
      "ArticleMenuPage",
      "ReviewMenuPage",
      "LifestyleArticleMenuPageFeatured",
      "TechArticleMenuPageFeatured",
      "EntertainmentArticleMenuPageFeatured",
    ].includes(props.specialOrigin)
      ? "absolute"
      : "relative"};
  width: ${(props) =>
    props.cardWidth && props.cardWidthNotDependentOnTitle
      ? "100%" // Fills entire CardLink which is sized by cardWidth
      : null};
  /* max-width: ${(props) => (props.isFeatured ? "" : "450px")}; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  /* border: 1px solid rgba(110, 110, 110, 0.2); */
  transition: all 0.37s;
  @media (hover: hover) {
    &:hover {
      transform: translateY(-2.9px);
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);
      transform: scale(1.014);
    }
  }
  overflow: hidden;
  padding-bottom: ${(props) =>
    props.specialOrigin === "ArticleFormat" ? "1px" : "1px"};
`;

const ImageContainer = styled.div`
  display: inline-block;
  width: ${(props) => props.imageWidth || "100%"};
  height: ${(props) => props.imageHeight || getHeight(props, "image")};
  overflow: hidden;
`;

const Image = styled.img`
  display: ${(props) =>
    props.isLoaded || props.isImageError ? "block" : "none"};
  margin: ${(props) => (props.isImageError ? "10px 0px 0px 10px" : "0px")};
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${(props) => {
    if (props.cardType === "textOnImage") {
      return "9px";
    } else if (
      props.cardType === "sideImage" &&
      (props.isFeatured || props.specialOrigin === "SearchResults")
    ) {
      return "10px 2px 2px 10px";
    } else {
      return "9px 9px 2px 2px";
    }
  }};
  -webkit-user-drag: none;
  pointer-events: none;
  transition: 0.3s;
  ${Card}:hover & {
    filter: saturate(110%);
  }
`;

const Excerpt = styled.p`
  margin: 4px 0;
  margin-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: ${(props) =>
    props.excerptSize
      ? "props.excerptSize"
      : mobileVersion(props, "excerpt") && !props.isFeatured
      ? mobileVersion(props, "excerpt")
      : parseInt(props.titleSize) <= 16
      ? parseInt(props.titleSize) - 2 > 12.5
        ? parseInt(props.titleSize) - 2 + "px"
        : "12.5px"
      : "16.5px"};
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* background: green; */
`;
const Title = styled.h2`
  margin: 0px;
  font-size: ${(props) =>
    props.titleSize
      ? props.titleSize
      : mobileVersion(props, "title")
      ? mobileVersion(props, "title")
      : "24px"};
  font-weight: bold;
  align-items: ${(props) => (props.disableExcerpt ? "center" : "auto")};
  justify-content: ${(props) => (props.disableExcerpt ? "center" : "auto")};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  min-width: ${(props) =>
    props.cardWidthNotDependentOnTitle ? "100%" : "max-content"};
  width: 100%;
  position: ${(props) =>
    props.cardType === "textOnImage" ? "absolute" : "static"};
  bottom: ${(props) =>
    props.cardType === "textOnImage"
      ? mobileVersion(props, "textOnImageBottom") || "9px"
      : "auto"};
  left: 0;
  right: 0;
  background-color: ${(props) =>
    props.cardType === "textOnImage" ? "rgba(0, 0, 0, 0.45)" : "transparent"};
  color: ${(props) => (props.cardType === "textOnImage" ? "white" : "inherit")};
  border-radius: ${(props) =>
    props.cardType === "textOnImage" ? "0 0 9px 9px" : "0"};

  padding: ${(props) =>
    props.cardType === "textOnImage" ? "4px 2px" : "5px 10px 2px 10px;"};
  line-height: ${(props) =>
    props.cardType === "textOnImage" ? "1.25em" : null};
  display: ${(props) => (props.disableExcerpt ? "flex" : "block")};
  height: ${(props) => (props.disableExcerpt ? "100%" : "auto")};
  /* background: yellow; */
  // opacity: 0.8;
`;

const ContentSide = styled.div`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${(props) => `calc(100% - ${props.imageWidth || "0px"})`};
  z-index: 0;
`;

const TextContainer = styled.div`
  width: 100%;
  flex-grow: ${(props) => (props.disableExcerpt ? "1" : "0")};
  display: ${(props) => (props.disableExcerpt ? "flex" : "block")};
  flex-direction: column;
  justify-content: ${(props) =>
    props.disableExcerpt ? "center" : "flex-start"};
  align-items: ${(props) => (props.disableExcerpt ? "center" : "flex-start")};
  margin-top: 6px;
  // background: magenta;
`;
const AuthorDateContainer = styled.div`
  width: 100%;
  margin: 0px;
`;

const AuthorDateAbsoluteContainer = styled.div`
  position: absolute;
  bottom: 4px;
  left: 40%;
`;

const AuthorDate = styled.p`
  font-size: 12.8px;
  color: #666;
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 5px;
  margin-left: 10px;
  z-index: 5;
  background: white;
`;

const Badge = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: ${(props) =>
    props.type === "quiz"
      ? "#f3f3f3"
      : props.type === "blank"
      ? "#f3f3f3"
      : "transparent"};
  color: #6c7879;
  color: #1f2937;
  color: ${(props) =>
    props.type === "quiz"
      ? "#d4af37"
      : props.type === "blank"
      ? "#d4af37"
      : "transparent"};
  padding: 4px 8px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;

const CardPreview = ({
  article, // Data
  onClick, // onClick event
  cardType = "default", // cardType: default, defaultDate, sideImage, textOnImage
  isFeatured, // Featured or not // Redundant? Get from article.isFeatured
  position, // Absolute or not, for featured content
  cardWidth, // cardWidth
  cardHeight, // cardHeight
  imageHeight, // Card image's height
  imageWidth, // Card image's width
  titleSize, // Card title's fontSize
  excerptSize, // Card excerpt's fontSize
  enableShorterExcerpt = false,
  disableExcerpt = false, // Disable only excerpt
  disableCard = false, // Disable entire card
  maxFontSize = 22, // Maximum fontSize for title / header
  minFontSize = 13, // Minimum fontSize for title / header
  cardWidthNotDependentOnTitle = false, // Whether card width should adjust based on title
  specialOrigin = null, // Where CardPreview called
  displayContentFormat, // Format DisplayContent portrays: articles, quizzes, reviews, mixed
  displayType, // displayType from DisplayContent: grid, scrollableSection
}) => {
  const titleRef = useRef(null);
  const cardRef = useRef(null);
  const [minCardWidth] = useState(null);
  const [windowWidth] = useWindowSize();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const titleText =
    // (cardType === 'default' && specialOrigin === 'SearchResults') ||
    (specialOrigin === "SearchResults" && article.title.length > 25) ||
    article.title.length > 15
      ? article.shorterTitle || article.title
      : article.title;

  // useEffect(() => {
  // if (
  // titleRef.current &&
  // cardType === 'textOnImage' &&
  // !isFeatured &&
  // minCardWidth === null
  // ) {
  // const resizeObserver = new ResizeObserver((entries) => {
  // for (const entry of entries) {
  // const width = entry.contentRect.width;
  // setMinCardWidth(`${width + 20}px`);
  // }
  // });

  // resizeObserver.observe(titleRef.current);

  // return () => {
  // resizeObserver.disconnect();
  // };
  // }
  // }, [cardType, minCardWidth]);

  const calculatedTitleSize = false;
  // const calculatedTitleSize = useFontSize(
  //     'cardPreview',
  //     (specialOrigin ? article.id + specialOrigin : article.id) +
  //         (displayType ? displayType : ''),
  //     titleText,
  //     maxFontSize,
  //     minFontSize,
  //     isFeatured || cardWidthNotDependentOnTitle,
  //     titleRef,
  //     cardRef,
  //     windowWidth,
  //     imageWidth,
  //     titleSize ||
  //         !(isFeatured || cardWidthNotDependentOnTitle) ||
  //         (isFeatured &&
  //             (cardType === 'textOnImage' || cardType === 'sideImage'))
  //     // So it does not save sizes not being used
  // );

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const excerptText =
    (isFeatured || enableShorterExcerpt) && article.shorterExcerpt
      ? article.shorterExcerpt
      : article.excerpt;

  const CardProps = {
    ref: cardRef,
    onDragStart: handleDragStart,
    cardType,
    isFeatured,
    cardWidth,
    cardHeight,
    style: position,
    windowWidth,
    minCardWidth,
    cardWidthNotDependentOnTitle,
    specialOrigin,
  };

  const transformImageUrl = (url) => {
    // return url;
    const targetSubstring = /w_\d+,h_\d+,c_scale/;
    const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;

    let newWidth, newHeight;
    if (screenWidth > 1280) {
      newWidth = 1280;
      newHeight = Math.round((newWidth * 720) / 1280);
    } else {
      newWidth = screenWidth;
      newHeight = Math.round((screenWidth * 720) / 1280);
    }

    const replacement = `w_${newWidth},h_${newHeight},c_scale`;
    return url.match(targetSubstring)
      ? url.replace(targetSubstring, replacement)
      : url;
  };

  const ImgProps = {
    src: transformImageUrl(article.image),
    alt: article.imageAltText,
    onLoad: () => setIsLoaded(true),
    onError: () => setIsImageError(true),
    isLoaded,
    isImageError,
    cardType,
    isFeatured,
    imageHeight,
    imageWidth,
    windowWidth,
    specialOrigin,
  };

  const TitleProps = {
    ref: titleRef,
    className: "article-title",
    cardType,
    disableExcerpt,
    titleSize:
      titleSize ||
      (isFeatured || cardWidthNotDependentOnTitle
        ? calculatedTitleSize || cardWidthNotDependentOnTitle
        : undefined),
    windowWidth,
    isFeatured,
    cardWidthNotDependentOnTitle,
  };

  const ExcerptProps = {
    cardType,
    excerptSize,
    titleSize:
      titleSize ||
      (isFeatured || cardWidthNotDependentOnTitle
        ? calculatedTitleSize || cardWidthNotDependentOnTitle
        : undefined),
    windowWidth,
    specialOrigin,
    isFeatured,
  };

  const handleCardClick = () => {
    firebaseAnalyticsLogEvent(
      "clicked_" +
        article.format +
        ([
          "startpage",
          "articlemenupage",
          "quizmenupage",
          "reviewmenupage",
        ].includes(specialOrigin.toLowerCase())
          ? "_" + specialOrigin.toLowerCase()
          : "") +
        (isFeatured ? "_featured" : "") +
        "_" +
        cardType
    );
  };

  const renderCard = () => {
    if (
      cardType === "sideImage" &&
      (isFeatured || specialOrigin === "SearchResults")
    ) {
      return (
        <Card {...CardProps}>
          {article.format === "quiz" &&
            displayContentFormat !== "quiz" &&
            specialOrigin !== "QuizMenuPage" && (
              <Badge type={"quiz"}>Quiz</Badge>
            )}
          <ImageContainer
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            cardType={cardType}
            windowWidth={windowWidth}
            specialOrigin={specialOrigin}
          >
            <Image {...ImgProps} />
          </ImageContainer>
          <ContentSide imageWidth={imageWidth}>
            <Title {...TitleProps}>{titleText}</Title>
            {!disableExcerpt && (
              <Excerpt {...ExcerptProps}>{excerptText}</Excerpt>
            )}
            {specialOrigin === "SearchResults" && (
              <AuthorDateAbsoluteContainer>
                <AuthorDate>
                  {article.updatedDate
                    ? article.updatedDate
                    : article.datePublished}
                </AuthorDate>
              </AuthorDateAbsoluteContainer>
            )}
          </ContentSide>
        </Card>
      );
    } else if (
      cardType === "defaultDate" && // Optional cardType
      (specialOrigin === "SearchResults" ||
        (!isFeatured && displayType !== "grid")) // For scrollable section
    ) {
      return (
        <Card {...CardProps}>
          {article.format === "quiz" && // Enable badge for quizzes except on QuizMenuPage
            displayContentFormat !== "quiz" &&
            specialOrigin !== "QuizMenuPage" && (
              <Badge type={"quiz"}>Quiz</Badge>
            )}
          <ImageContainer
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            cardType={cardType}
            windowWidth={windowWidth}
            specialOrigin={specialOrigin}
          >
            <Image {...ImgProps} />
          </ImageContainer>
          <TextContainer disableExcerpt={disableExcerpt}>
            <Title {...TitleProps}>{titleText}</Title>
            <AuthorDateContainer>
              <AuthorDate>
                {article.updatedDate
                  ? article.updatedDate
                  : article.datePublished}
              </AuthorDate>
            </AuthorDateContainer>
            {!disableExcerpt && (
              <Excerpt {...ExcerptProps}>{excerptText}</Excerpt>
            )}
          </TextContainer>
        </Card>
      );
    } else {
      return (
        <Card {...CardProps}>
          {article.format === "quiz" &&
            displayContentFormat !== "quiz" &&
            specialOrigin !== "QuizMenuPage" && (
              <Badge type={"quiz"}>Quiz</Badge>
            )}
          <ImageContainer
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            cardType={cardType}
            windowWidth={windowWidth}
            specialOrigin={specialOrigin}
          >
            <Image {...ImgProps} />
          </ImageContainer>

          <Title {...TitleProps}>{titleText}</Title>

          {cardType !== "textOnImage" && !disableExcerpt && (
            <Excerpt {...ExcerptProps}>{excerptText}</Excerpt>
          )}
        </Card>
      );
    }
  };
  return (
    <CardLink
      // to={`/${formatToPath(article.format)}/${article.slug}`}
      to={formatArticlePath(article)}
      onClick={onClick ? onClick : handleCardClick}
      cardWidth={cardWidth}
      cardWidthNotDependentOnTitle={cardWidthNotDependentOnTitle}
    >
      {!disableCard && renderCard()}
    </CardLink>
  );
};

export default CardPreview;
