import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../utils/hooks/useWindowSize';
import DisplayContent from '../components/DisplayContent';
import { articles } from '../data/Articles';

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    color: #333;
`;

const ErrorCode = styled.h1`
    font-size: 4rem;
    @media (min-width: 400px) {
        font-size: 5rem;
    }
    @media (min-width: 450px) {
        font-size: 6rem;
    }
    @media (min-width: 730px) {
        font-size: 8rem;
    }
    @media (min-width: 870px) {
        font-size: 8.5rem;
    }

    font-weight: 700;
    margin: 0;
    font-family: 'Montserrat', sans-serif;

    margin-top: 50px;
    @media (min-width: 450px) {
        margin-top: 100px;
    }
    @media (min-width: 600px) {
        margin-top: 150px;
    }
    @media (min-width: 800px) {
        margin-top: 200px;
    }
`;

const ErrorMessagePartOne = styled.p`
    font-size: ${(props) =>
        props.windowWidth < 450
            ? '1.3rem'
            : props.windowWidth < 550
            ? '1.5rem'
            : props.windowWidth < 580
            ? '1.3rem'
            : props.windowWidth < 610
            ? '1.4rem'
            : props.windowWidth < 650
            ? '1.5rem'
            : props.windowWidth < 730
            ? '1.6rem'
            : props.windowWidth < 870
            ? '1.8rem'
            : '2rem'};
    font-family: 'Nunito', sans-serif;
    text-align: center;
`;

const ErrorMessagePartTwo = styled(ErrorMessagePartOne)`
    margin-top: -15px;
`;

const ReturnLink = styled.a`
    display: inline-block;
    font-size: ${(props) =>
        props.windowWidth < 400
            ? '0.85rem'
            : props.windowWidth < 450
            ? '0.9rem'
            : props.windowWidth < 600
            ? '0.9rem'
            : props.windowWidth < 730
            ? '1rem'
            : props.windowWidth < 870
            ? '1.1rem'
            : '1.3rem'};
    text-decoration: none;
    color: #1f2937;
    border: 1px solid #4b5563;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: ${(props) =>
        props.windowWidth < 500
            ? '10px'
            : props.windowWidth < 900
            ? '15px'
            : '20px'};
    transition: background-color 0.25s ease, color 0.25s ease;
    font-family: 'Nunito', sans-serif;
    text-shadow: 1px 1px #d1d5db;

    &:hover {
        background-color: #1f2937;
        color: #f9fafb;
    }
`;

const DisplayContentContainer = styled.div`
    max-width: calc(100% - 40px);
    margin-top: 40px; // Added margin to push the container to bottom.
    padding-left: 10px;
    padding-right: 10px;
    /* background: gold; */
`;

const NotFoundPage = () => {
    const [windowWidth, windowHeight] = useWindowSize();
    return (
        <ErrorContainer>
            <ErrorCode windowWidth={windowWidth} windowHeight={windowHeight}>
                404
            </ErrorCode>
            {windowWidth < 550 ? (
                <>
                    <ErrorMessagePartOne windowWidth={windowWidth}>
                        Sorry, the page you're looking for
                    </ErrorMessagePartOne>
                    <ErrorMessagePartTwo windowWidth={windowWidth}>
                        cannot be found!
                    </ErrorMessagePartTwo>
                </>
            ) : (
                <ErrorMessagePartOne windowWidth={windowWidth}>
                    Sorry, the page you're looking for cannot be found!
                </ErrorMessagePartOne>
            )}
            <ReturnLink href='/' windowWidth={windowWidth}>
                Return to Home
            </ReturnLink>
            <DisplayContentContainer windowHeight={windowHeight}>
                <DisplayContent
                    type={'scrollableSection'}
                    category={'latest'}
                    articles={articles}
                    windowWidth={windowWidth}
                    stickToBottom={true}
                />
            </DisplayContentContainer>
        </ErrorContainer>
    );
};

export default NotFoundPage;
