import { useState, useEffect, useCallback } from 'react';
import { css, keyframes } from 'styled-components';

export default function useMenuAnimation() {
    const [anim, setAnim] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const startClosingAnimation = useCallback(() => {
        setIsClosing(true);
        setIsAnimating(true);
    }, []);

    const stopClosingAnimation = useCallback(() => {
        setIsClosing(false);
        setIsAnimating(false);
    }, []);

    const menuAnimation = keyframes`
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    `;

    const menuCloseAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    `;

    const animation = css`
        ${menuAnimation} 0.2s forwards
    `;

    const closingAnimation = css`
        ${menuCloseAnimation} 0.15s forwards
    `;

    const startAnimation = useCallback(() => {
        if (!anim && !isClosing && !isAnimating) {
            setAnim(true);
            setIsAnimating(true);
        }
    }, [anim, isAnimating, isClosing]);

    const stopAnimation = useCallback(() => {
        setAnim(false);
        stopClosingAnimation();
    }, [stopClosingAnimation]);

    useEffect(() => {
        if (anim && !isAnimating) {
            const timer = setTimeout(() => {
                stopAnimation();
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [anim, isAnimating, stopAnimation]);

    useEffect(() => {
        if (isClosing && !isAnimating) {
            const timer = setTimeout(() => {
                stopClosingAnimation();
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [isClosing, isAnimating, stopClosingAnimation]);

    return {
        animation,
        closingAnimation,
        startClosingAnimation,
        startAnimation,
        stopAnimation,
        anim,
        isClosing,
        stopClosingAnimation,
    };
}
