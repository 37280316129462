import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";

// Configs
import { helmet } from "../utils/config";

// Utils
import {
  // isLocalStorageItemAvailable,
  mobileVersion,
} from "../data/positionsGrid";
import {
  articles,
  // , convertDateStringToProperFormat
} from "../data/Articles";

// Hooks
// import { useFontSize } from '../utils/hooks/useFontSize';
import useWindowSize from "../utils/hooks/useWindowSize";
import { useGradualLoad } from "../utils/hooks/useGradualLoad";
import useYouTubeEmbed from "../utils/hooks/useYouTubeEmbed";

// Components
import DisplayContent from "../components/DisplayContent";
import { ShareLinks } from "../components/ShareLinks";

// Pages
import NotFoundPage from "../pages/NotFoundPage";
import RelatedArticles from "../components/RelatedArticles";

export const Container = styled.div`
  max-width: 1280px;
  margin: 40px auto;
  margin-top: -10px;
  margin-bottom: 0px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: ${(props) => props.windowHeight + "px"};
`;
// const ArticlePlaceholder = styled.div`
// width: 97%;
// height: ${(props) => props.windowHeight + 300 + 'px'};
// z-index: 4;
// background-color: white;
// position: absolute;
// `;

const TableOfContentsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) =>
    mobileVersion(props, "articleTOCMaxWidthClosed")
      ? mobileVersion(props, "articleTOCMaxWidthClosed")
      : "225px"};
  margin: 15px auto auto auto;
  border-radius: ${(props) =>
    mobileVersion(props, "articleTOCBorderRadiusClosed")
      ? mobileVersion(props, "articleTOCBorderRadiusClosed")
      : "20px"};
  padding: ${(props) =>
    mobileVersion(props, "articleTOCPaddingClosed")
      ? mobileVersion(props, "articleTOCPaddingClosed")
      : "5px 10px"};
  background: white;
  border: 1px solid rgba(75, 85, 99, 0.3);
  user-select: none;
  // Transition for closing
  transition-property: max-width, border-radius, padding, max-height;
  transition-duration: 0.35s, 0.35s, 0.35s, 0.35s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  .toc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin-bottom: -2px;
  }

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 1.14rem;
  }

  span {
    position: relative;
    top: -2px;
    font-size: 0.75rem;
    margin-left: 5px;
    color: #1f2430;
  }

  .toc-content {
    max-height: 0;
    overflow: hidden;
    // Transition timing for the content when it's closing
    transition: max-height 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.open {
    border-radius: 8px;
    max-width: 350px;
    padding: 5px 20px;
    // Transition for opening (duration increased)
    transition-property: max-width, border-radius, padding, max-height;
    transition-duration: 0.3s, 0.5s, 0.5s, 0.7s;
    transition-delay: 0s, 0s, 0s, 0.1s; // Delay for height increase
    transition-timing-function: cubic-bezier(0.4, 0.1, 0.2, 1);

    .toc-content {
      max-height: 1000px;
      // Transition timing for the content when it's opening, slower at the end
      transition: max-height 0.5s cubic-bezier(0.5, 0.3, 0.7, 1);
      transition-delay: 0.1s; // Delay for height increase
    }
  }

  &:not(.open) {
    // Delay for width, radius, and padding decrease
    transition-delay: 0.17s, 0.17s, 0.17s, 0s;

    .toc-content {
      transition-delay: 0s; // No delay for height decrease
    }
  }
`;

const TOCItem = styled.div`
  margin: 11px 0;
  margin-left: 8px;
  cursor: pointer;
  color: #363d3d;
  max-width: 270px;

  &:hover {
    opacity: 0.8;
  }
`;

export const DisplayContentContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const Header = styled.h1`
  margin-bottom: 12px;
  margin-top: 0px;
  font-size: ${(props) =>
    mobileVersion(props, "articleFormatTitleSize")
      ? mobileVersion(props, "articleFormatTitleSize")
      : "31px"};
`;
export const AuthorDate = styled.div`
  font-size: 0.85rem;
  color: #718096;
  margin-bottom: 5px;
  margin-top: -2px;
  font-weight: 600;
  display: flex;
  margin-right: -40px;
  align-items: center;
  /* background: cyan; */
`;
export const AuthorName = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  /* background: green; */
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 13px;
  margin-left: 5px;
  max-height: ${(props) => (props.showAllTags ? "1000px" : "auto")};
  /* overflow: hidden; */
  transition: max-height 0.3s ease-in-out;
  /* background-color: black; */
`;
export const Tag = styled.span`
  background: #f3f3f3;
  padding: 3px 6px;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
  font-size: ${(props) => {
    const baseFontSize = 0.9;
    const baseWindowWidth = 1280;
    const decreaseFactor = 0.0001;
    let fontSize = baseFontSize;

    if (props.windowWidth < baseWindowWidth) {
      const decreaseAmount =
        (baseWindowWidth - props.windowWidth) * decreaseFactor;
      fontSize -= decreaseAmount;
    }
    // console.log('fontSize', fontSize);
    return `${fontSize}rem`;
  }};
  // margin-top: -20px;
  color: #6c7879;

  @media (hover: hover) {
    &:hover {
      background: #e2e2e2;
    }
  }

  &.active {
    background: #e2e2e2;
  }
`;

export const ArrowTag = styled(Tag)`
  margin: auto 0;
  margin-bottom: 1px;
  margin-left: -5px;

  background: none;
  font-size: 20px;

  &:hover {
    opacity: 0.8;
    background: none;
  }
`;

const Body = styled.div`
  max-width: 650px;
  margin: 0 auto;
  word-wrap: break-word;
  font-size: 17px;
  line-height: 1.8;
  overflow-y: auto;
  /* margin-right: 5px */

  h2 {
    margin: 0px;
    margin-top: 22px;
    color: #45484a;
    font-size: ${(props) =>
      mobileVersion(props, "articleBodyH2Size")
        ? mobileVersion(props, "articleBodyH2Size")
        : "1.5em"};
    font-family: "Montserrat", sans-serif;
    /* font-weight: 500; */
  }

  h3 {
    margin-bottom: 0px;
    color: black;
    font-weight: 600;
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 1.3em;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .image-container {
    /* display: inline-block; */
    text-align: center;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;

    img {
      width: 100%;
      max-height: ${(props) =>
        props.windowWidth * 0.45 < 350
          ? props.windowWidth * 0.45 + "px"
          : "370px"};
      min-height: 110px;
      height: auto;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .image-caption {
    line-height: 1;
    font-size: 0.9rem;
    color: #718096;
    font-style: italic;
    display: block;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
`;

export const ImageContainer = styled.div`
  display: inline-block;
  max-width: 100%;
  width: 800px;
  padding-bottom: 50%; // For 16:9 ratio, 56.25% = 100% (width) * 9 / 16
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: ${(props) => (props.isLoaded ? "0px" : "400px")};
`;

export const BodyImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const ImageCaption = styled.figcaption`
  font-size: 0.9rem;
  color: #45484a;
  font-style: italic;
  text-align: center;
`;

export const StyledLazyLoadImage = styled(LazyLoadImage)`
  &.lazy-load-image-background.blur {
    filter: blur(0px);
    transition: filter 0s;
  }

  &.lazy-load-image-background.blur.lazy-load-image-loaded {
    filter: blur(0);
  }
`;

const ArticleFormat = () => {
  const { slug } = useParams();
  const article = useMemo(
    () => articles.find((article) => article.slug === slug),
    // eslint-disable-next-line
    [slug, articles]
  );

  if (!article) {
    return <NotFoundPage />;
  }

  return <ActualArticleFormat article={article} />;
};
const ActualArticleFormat = ({ article }) => {
  const [bodyImageLoaded, setBodyImageLoaded] = useState(false);
  const [tagContainerHeight, setTagContainerHeight] = useState("50px");
  const [showAllTags, setShowAllTags] = useState(false);
  // const titleRef = useRef(null);
  // const titleWrapperRef = useRef(null);
  const urlRef = useRef(window.location.href);
  const navigate = useNavigate();
  const location = useLocation();
  const [windowWidth, windowHeight] = useWindowSize();
  const [isLoaded, setIsLoaded] = useState(false);

  const [tocItems, setTocItems] = useState([]);
  const tocItemRefs = useRef([]);
  const [showTOC, setShowTOC] = useState(false);

  // const [titleSizeReady, setTitleSizeReady] = useState(
  // (windowWidth > 800 && !article.title > 40) ||
  // isLocalStorageItemAvailable(
  // `calculatedTitleSize-${article.id}-ArticleFormat`
  // )
  // ? true
  // : false
  // );

  const [videoEmbed] = useYouTubeEmbed(article.videoId, 40, "0px auto");

  const [contentBeforeH2, contentAfterH2] = useMemo(() => {
    let modifiedContent = article.content;

    // Resizing images before they are displayed for SEO
    const cloudinaryUserID = "dmhwy4puw";
    const desiredWidth = windowWidth > 500 ? "800" : "500";

    const cloudinaryRegex = new RegExp(
      `(https://res\\.cloudinary\\.com/${cloudinaryUserID}/image/upload/)([^"']*)`,
      "gi"
    );

    modifiedContent = modifiedContent.replace(
      cloudinaryRegex,
      `$1w_${desiredWidth}/$2`
    );

    let h2Count = 0;
    modifiedContent = modifiedContent.replace(/<h2/gi, () => {
      return `<h2 id="toc-item-${h2Count++}"`;
    });

    // Find the index of the second h2 to place the video before it
    const h2Index = modifiedContent.indexOf(
      '<h2 id="toc-item-',
      modifiedContent.indexOf('<h2 id="toc-item-') + 1
    );
    const contentBeforeH2 = modifiedContent.slice(0, h2Index);
    const contentAfterH2 = modifiedContent.slice(h2Index);

    if (h2Count === 0) {
      const paragraphs = modifiedContent.split("</p>").slice(0, -1); // Splitting by paragraphs

      // Let's assume you want to display the first 3 paragraphs immediately
      const immediateParagraphs = paragraphs.slice(0, 3).join("</p>");
      const restOfTheContent = paragraphs.slice(3).join("</p>");

      return [immediateParagraphs, restOfTheContent];
    }

    return [contentBeforeH2, contentAfterH2];
  }, [article.content, windowWidth]);

  const { chunks, currentIndex, loadMoreRef } = useGradualLoad(contentAfterH2);

  useEffect(() => {
    const parser = new DOMParser();

    // Parse both contentBeforeH2 and contentAfterH2 to extract h2s
    const htmlDocBefore = parser.parseFromString(contentBeforeH2, "text/html");
    const htmlDocAfter = parser.parseFromString(contentAfterH2, "text/html");

    const h2sBefore = htmlDocBefore.getElementsByTagName("h2");
    const h2sAfter = htmlDocAfter.getElementsByTagName("h2");

    const tocItemsBefore = Array.from(h2sBefore).map((h2, index) => ({
      id: `toc-item-${index}`,
      title: h2.textContent,
    }));

    const tocItemsAfter = Array.from(h2sAfter).map((h2, index) => ({
      id: `toc-item-${index + h2sBefore.length}`, // Offset the index by the number of h2s in contentBeforeH2
      title: h2.textContent,
    }));

    setTocItems([...tocItemsBefore, ...tocItemsAfter]);
  }, [contentBeforeH2, contentAfterH2]);

  const handleTOCHeaderClick = () => {
    setShowTOC((prev) => !prev);
  };

  useEffect(() => {
    tocItemRefs.current = tocItems.reduce((acc, item) => {
      acc[item.id] = document.getElementById(item.id);
      return acc;
    }, {});
  }, [tocItems, chunks]);

  useEffect(() => {
    if (chunks.length > 0) {
      // corrected reference
      chunks.forEach((chunk, idx) => {
        // corrected reference
        const parser = new DOMParser();
        const htmlChunk = parser.parseFromString(
          chunk.join("</p>") + "</p>",
          "text/html"
        );
        const h2s = htmlChunk.getElementsByTagName("h2");
        Array.from(h2s).forEach((h2, index) => {
          h2.setAttribute("id", `toc-item-${idx * 10 + index}`);
        });
        chunk = htmlChunk.body.innerHTML.split("</p>");
      });
    }
  }, [chunks]);

  const handleTOCItemClick = (id) => {
    const element = document.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // const calculatedTitleSize = useFontSize(
  // 'articleFormat',
  // article.id + '-ArticleFormat',
  // article.title,
  // mobileVersion(windowWidth, 'articleTitleMaxSize')
  // ? mobileVersion(windowWidth, 'articleTitleMaxSize')
  // : 40, // maxFontSize
  // 25, // minFontSize
  // true, // isFeatured
  // titleRef,
  // titleWrapperRef,
  // windowWidth,
  // null,
  // false
  // );

  // useEffect(() => {
  // setTimeout(() => {
  // setTitleSizeReady(true);
  // }, 4);
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 90);
  }, []);

  const handleTagClick = useCallback(
    (tag) => {
      navigate(`/search?query=${tag}`);
    },
    [navigate]
  );

  const handleShowMoreClick = useCallback(() => {
    const tagHeight = 40;
    const tagContainerPadding = 10;
    const rows = Math.ceil(article.tags.length / 4);
    const newHeight = tagHeight * rows + tagContainerPadding;

    setTagContainerHeight(showAllTags ? "50px" : `${newHeight}px`);
    setShowAllTags(!showAllTags);
  }, [showAllTags, article.tags.length]);

  const displayedTags = showAllTags ? article.tags : article.tags.slice(0, 4);

  const handleAuthorClick = (author) => {
    navigate(`/search?query=${author}`);
  };

  // InArticleAd
  // const InArticleAd = () => {
  // useEffect(() => {
  // (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  // return (
  // <>
  // <script
  // async
  // src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-797845645645656'
  // crossorigin='anonymous'
  // ></script>
  // <ins
  // className='adsbygoogle'
  // style={{ display: 'block', textAlign: 'center' }}
  // data-ad-layout='in-article'
  // data-ad-format='fluid'
  // data-ad-client='ca-pub-797845645645656'
  // data-ad-slot='2945758923'
  // ></ins>
  // </>
  // );
  // };

  return (
    <Container windowHeight={windowHeight}>
      <Helmet>
        <title>{`${article.title} | Infoblis`}</title>
        <meta
          name="description"
          content={article.description || article.excerpt}
        />
        <link
          rel="canonical"
          href={`https://infoblis.com${location.pathname}`}
        />
        <meta name="keywords" content={article.tags.join(", ")} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.title} />
        <meta
          property="og:description"
          content={article.description || article.excerpt}
        />
        <meta property="og:image" content={article.image} />
        <meta
          property="og:url"
          content={`https://infoblis.com${location.pathname}`}
        />
        <meta property="og:site_name" content="Infoblis" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={helmet.TwitterHandle} />
        <meta name="twitter:title" content={article.title} />
        <meta
          name="twitter:description"
          content={article.description || article.excerpt}
        />
        <meta name="twitter:image" content={article.image} />
        {/* <script type="application/ld+json">
 {(() => {
 let schema = {
 "@context": "https://schema.org",
 "@type": article.typeSchema,
 mainEntityOfPage: {
 "@type": "WebPage",
 "@id": `https://infoblis.com${location.pathname}`,
 },
 headline: article.title,
 description: article.description || article.excerpt,
 datePublished: convertDateStringToProperFormat(
 article.datePublished
 ),
 author: {
 "@type": "Person",
 name: article.author,
 },
 image: article.image,
 publisher: {
 "@type": "Organization",
 name: "infoblis",
 logo: {
  "@type": "ImageObject",
  url: helmet.LogoUrl,
 },
 },
 };

 if (article.updatedDate) {
 schema.dateModified = convertDateStringToProperFormat(
 article.updatedDate
 );
 }

 return JSON.stringify(schema);
 })()}
 </script> */}
      </Helmet>
      {/* {!titleSizeReady && (
 <ArticlePlaceholder windowHeight={windowHeight} />
 )} */}
      <TitleWrapper
      // ref={titleWrapperRef}
      >
        <Header
          // ref={titleRef}
          // calculatedTitleSize={calculatedTitleSize}
          titleLength={article.title.length}
          windowWidth={windowWidth}
          // titleSizeReady={titleSizeReady}
        >
          {article.title}
        </Header>
        <AuthorDate>
          <AuthorName onClick={() => handleAuthorClick(article.author)}>
            {`${article.author} |\u00A0`}
          </AuthorName>

          {article.updatedDate
            ? "Updated " + article.updatedDate
            : article.datePublished}
          <ShareLinks url={urlRef.current} format={article.format} />
        </AuthorDate>
        <TagContainer
          showAllTags={showAllTags}
          style={{ maxHeight: tagContainerHeight }}
        >
          {displayedTags.map((tag, index) => (
            <Tag
              key={index}
              windowWidth={windowWidth}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </Tag>
          ))}
          {article.tags.length > 4 && (
            <ArrowTag
              onClick={handleShowMoreClick}
              style={{ cursor: "pointer" }}
            >
              {showAllTags ? (
                <AiOutlineLeft
                  style={{
                    position: "relative",
                    top: "2px",
                    fontSize: "0.8em",
                  }}
                />
              ) : (
                <AiOutlineRight
                  style={{
                    position: "relative",
                    top: "2px",
                    fontSize: "0.8em",
                  }}
                />
              )}
            </ArrowTag>
          )}
        </TagContainer>
      </TitleWrapper>
      {article.imageAlternative !== "none" && (
        <ImageWrapper>
          <ImageContainer isLoaded={bodyImageLoaded} windowWidth={windowWidth}>
            <BodyImage
              src={
                article.imageAlternative
                  ? article.imageAlternative
                  : article.image
              }
              alt={article.imageAltText}
              onLoad={() => setBodyImageLoaded(true)}
              onError={() => setBodyImageLoaded(true)} // show alt text if there's an error
              isLoaded={bodyImageLoaded}
            />
          </ImageContainer>
          <ImageCaption>{article.imageCaption}</ImageCaption>
        </ImageWrapper>
      )}
      <article>
        {article.tableOfContentEnabled && (
          <TableOfContentsContainer
            windowWidth={windowWidth}
            className={showTOC ? "open" : ""}
          >
            <div className="toc-header" onClick={handleTOCHeaderClick}>
              <h4>
                Table of Contents
                <span>{showTOC ? "▲" : "▼"}</span>
              </h4>
            </div>
            <div className="toc-content">
              {tocItems.map((item) => (
                <TOCItem
                  key={item.id}
                  onClick={() => handleTOCItemClick(item.id)}
                >
                  {item.title}
                </TOCItem>
              ))}
            </div>
          </TableOfContentsContainer>
        )}
        <Body
          windowWidth={windowWidth}
          dangerouslySetInnerHTML={{ __html: contentBeforeH2 }}
        />

        {videoEmbed}

        {chunks.slice(0, currentIndex).map((chunk, index) => (
          <React.Fragment key={index}>
            {/* {index === 1 && <InArticleAd />} */}
            {(index > 3 ? index === 3 : index === 1) && ( // If more chunks then it should be further down
              <RelatedArticles article={article} articles={articles} />
            )}
            <Body
              windowWidth={windowWidth}
              dangerouslySetInnerHTML={{
                __html: chunk.join("</p>") + "</p>",
              }}
            />
          </React.Fragment>
        ))}

        <div
          ref={loadMoreRef}
          style={{
            height: 20,
            margin: "30px 0",
            position: "absolute",
          }}
        >
          {currentIndex < chunks.length ? "" : ""}
        </div>
        {/* <RelatedArticles article={article} articles={articles} /> */}
      </article>

      <DisplayContentContainer>
        <DisplayContent
          type={article.displayGridType.type}
          category={article.displayGridType.category}
          articles={articles}
          windowWidth={windowWidth}
          currentArticleId={article.id}
          specialOrigin={"ArticleFormat"}
          isLoaded={isLoaded}
        />
      </DisplayContentContainer>
    </Container>
  );
};

export default ArticleFormat;
