import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../utils/hooks/useWindowSize';
import { firebaseAnalyticsLogEvent } from '../../Firebase';
import { theme } from '../utils/config';

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: ${(props) => (props.isMobile ? 'static' : 'relative')};
    cursor: pointer;
    margin-right: 20px;
    // background: lightBlue;
`;

const SearchIconContainer = styled.div`
    margin-right: ${(props) => (props.isMobile ? '5px' : '0px')};
    display: flex;
    align-items: center;
    // background: green;
`;

const SearchText = styled.span`
    display: ${(props) => (props.isMobile ? 'none' : 'inline')};
    margin-left: 5px;
    user-select: none;
`;
const SearchInput = styled.input`
    position: absolute;
    top: ${(props) => (props.isMobile ? '32.3%' : '-2px')};
    right: ${(props) => (props.isMobile ? '5px' : '100%')};
    background-color: #2d3748;
    border: none;
    border-radius: 3px;
    color: ${theme.colors.buttonText};
    padding: 5px 10px;
    padding-right: ${(props) => (props.isMobile ? '5%' : '26px')};
    font-size: 16px;
    outline: none;
    margin-right: ${(props) => (props.isMobile ? '36px' : '10px')};
    width: ${(props) => {
        if (props.isMobile) {
            return props.windowWidth - 110 + 'px';
        } else if (props.showSearchInput) {
            let defaultWidth = 350;
            let adjustedWidth = props.windowWidth - 290;
            return (
                (defaultWidth > adjustedWidth ? adjustedWidth : defaultWidth) +
                'px'
            );
        } else {
            return '100%';
        }
    }};
    opacity: ${(props) => (props.showSearchInput ? '1' : '0')};
    pointer-events: ${(props) => (props.showSearchInput ? 'auto' : 'none')};
    transition: width ${(props) => (props.showSearchInput ? '0.27s' : '0.3s')}
            ease-in-out,
        opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    white-space: nowrap;
    display: block;
    transform: ${(props) =>
        props.isMobile && props.showSearchInput
            ? 'translateX(0%) scaleX(1)'
            : props.isMobile
            ? 'translateX(50%) scaleX(0)'
            : 'translateX(0) scaleX(1)'};
`;

const SearchToggleContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-in-out;
    position: ${(props) =>
        props.isMobile && props.showSearchInput ? 'absolute' : 'static'};
    right: 9px;
    z-index: 10;

    transform: ${(props) =>
        props.isMobile && props.showSearchInput
            ? 'translateX(-3px)'
            : 'translateX(0)'};
    transition: ${(props) => (props.isMobile ? '0.2s' : '0s')} ease-in-out;

    @media (hover: hover) {
        &:hover {
            color: #718096;
        }
    }

    &.active {
        color: #718096;
    }
`;
const ClearButtonContainer = styled.div`
    position: absolute;
    top: ${(props) => (props.isMobile ? '52%' : '62%')};
    right: ${(props) => (props.isMobile ? '40px' : '88px')};
    transform: translateY(-50%);
    padding: 8px;
    z-index: 1;
    opacity: ${(props) =>
        props.showClearButton && props.showSearchInput ? '1' : '0'};
    transition: opacity 0.12s ease-in-out;
    align-items: center;
    justify-content: center;
    pointer-events: ${(props) => (props.showClearButton ? 'auto' : 'none')};
    cursor: ${(props) => (props.showClearButton ? 'pointer' : 'default')};
`;

const ClearButton = styled(FaTimes)`
    font-size: 14px;
    cursor: pointer;
    transition: opacity ${(props) => (props.showClearButton ? '0.08s' : '0.2s')}
            ease-in-out,
        transform 0.1s ease-in-out;

    @media (hover: hover) {
        &:hover {
            color: #718096;
        }
    }

    &.active {
        color: #718096;
    }
`;

const SearchBar = ({
    handleTouchStart,
    handleTouchEnd,
    isMobile,
    showSearchInput,
    setShowSearchInput,
}) => {
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [windowWidth] = useWindowSize();

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const query = encodeURIComponent(searchInputRef.current.value).replace(
            /%20/g,
            '+'
        );
        navigate(`/search?query=${query}`);

        if (isMobile) {
            searchInputRef.current.blur();
        }
        firebaseAnalyticsLogEvent('search');
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const clearInput = useCallback(() => {
        setInputValue('');
        searchInputRef.current.value = '';
        searchInputRef.current.focus();
    }, []);

    // const toggleSearchInput = useCallback((event) => {
    // if (
    // event.target === event.currentTarget ||
    // event.target.parentNode === event.currentTarget ||
    // event.target.parentNode.parentNode === event.currentTarget
    // ) {
    // setShowSearchInput((prevState) => !prevState);
    // }
    // }, []);

    const toggleSearchInput = useCallback(() => {
        setShowSearchInput((prevState) => !prevState);
    }, [setShowSearchInput]);

    useEffect(() => {
        if (showSearchInput) {
            searchInputRef.current.focus();
        }
    }, [showSearchInput]);

    return (
        <SearchContainer isMobile={isMobile}>
            <SearchToggleContainer
                onClick={toggleSearchInput}
                showSearchInput={showSearchInput}
                isMobile={isMobile}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <SearchIconContainer isMobile={isMobile}>
                    <FaSearch />
                </SearchIconContainer>
                <SearchText isMobile={isMobile}>Search</SearchText>
            </SearchToggleContainer>
            <form onSubmit={handleSearchSubmit}>
                <SearchInput
                    id='SearchInput'
                    ref={searchInputRef}
                    showSearchInput={showSearchInput}
                    isMobile={isMobile}
                    placeholder='Type to search'
                    onChange={handleInputChange}
                    windowWidth={windowWidth}
                    // disabled={!showSearchInput}
                />
                <ClearButtonContainer
                    showClearButton={!!inputValue}
                    showSearchInput={showSearchInput}
                    onClick={clearInput}
                    isMobile={isMobile}
                >
                    <ClearButton />
                </ClearButtonContainer>
            </form>
        </SearchContainer>
    );
};

export default SearchBar;
