import React, { memo, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { articles, sortArticlesByPriority } from '../data/Articles';
import {
    getUpdatedPositions,
    isLocalStorageItemAvailable,
    positions,
} from '../data/positionsGrid';
import DisplayContent from '../components/DisplayContent';
import useWindowSize from '../utils/hooks/useWindowSize';
import {
    ArticlePlaceholder,
    Container,
    FeaturedArticle,
    FeaturedSection,
    GridContainer,
    InnerContainer,
    ScrollableContainer,
} from './StartPage';
import { helmet } from '../utils/config';

const DisplayContentMemo = memo(DisplayContent);

const EntertainmentArticleMenuPage = () => {
    const sortedArticles = useMemo(
        () => sortArticlesByPriority(articles),
        // eslint-disable-next-line
        [articles]
    );

    const ArticleSpecialOrigin = 'ArticleMenuPage';

    const featuredArticles = useMemo(
        () =>
            sortedArticles
                .filter((article) =>
                    article.section.includes('entertainmentArticleMenuPageFeatured')
                )
                .slice(0, positions.length),
        [sortedArticles]
    );

    const [titleSizeReady, setTitleSizeReady] = useState(
        isLocalStorageItemAvailable('calculatedTitleSize', ArticleSpecialOrigin)
            ? true
            : false
    );

    const [windowWidth, windowHeight] = useWindowSize();

    useEffect(() => {
        const timer = setTimeout(() => {
            setTitleSizeReady(true);
        }, 14);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            <Helmet>
                <title>{helmet.ArticleMenuPageTitle}</title>
                <meta
                    name='description'
                    content={helmet.ArticleMenuPageDescription}
                />
                <link
                    rel='canonical'
                    href={`https://infoblis.com${useLocation().pathname}`}
                />
                <meta
                    property='og:title'
                    content={helmet.ArticleMenuPageOGTitle}
                />
                <meta
                    property='og:description'
                    content={helmet.ArticleMenuPageOGDescription}
                />
                <meta
                    name='keywords'
                    content={helmet.ArticleMenuPageKeywords}
                />
                <script type='application/ld+json'>
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'CollectionPage',
                        headline: helmet.ArticleMenuPageTitle,
                        description: helmet.ArticleMenuPageDescription,
                        url: `https://infoblis.com${useLocation().pathname}`,
                        publisher: {
                            '@type': 'Organization',
                            name: 'infoblis',
                            logo: {
                                '@type': 'ImageObject',
                                url: helmet.LogoUrl,
                            },
                        },
                    })}
                </script>
            </Helmet>
            {!titleSizeReady && (
                <ArticlePlaceholder windowHeight={windowHeight} />
            )}
            <FeaturedSection windowWidth={windowWidth}>
                {featuredArticles.map((article, index) => {
                    const updatedPositions = getUpdatedPositions(windowWidth);
                    return (
                        <FeaturedArticle
                            key={article.id}
                            article={article}
                            cardType={updatedPositions[index].featuredCardType}
                            isFeatured={true}
                            position={updatedPositions[index]}
                            cardWidth={updatedPositions[index].width}
                            cardHeight={updatedPositions[index].height}
                            imageHeight={updatedPositions[index].imageHeight}
                            imageWidth={updatedPositions[index].imageWidth}
                            titleSize={updatedPositions[index].titleSize}
                            excerptSize={updatedPositions[index].excerptSize}
                            disableExcerpt={
                                updatedPositions[index].disableExcerpt
                            }
                            disableCard={updatedPositions[index].disableCard}
                            windowWidth={windowWidth}
                            specialOrigin={ArticleSpecialOrigin}
                        />
                    );
                })}
            </FeaturedSection>
            <InnerContainer>
                <ScrollableContainer>
                    <DisplayContentMemo
                        type={'scrollableSection'}
                        category={'latest'}
                        displayContentFormat={'article'}
                        displayContentTopic={'entertainment'}
                        articles={articles}
                        windowWidth={windowWidth}
                        specialOrigin={ArticleSpecialOrigin}
                    />
                </ScrollableContainer>
                {windowWidth > 680 ? (
                    <GridContainer>
                        <DisplayContentMemo
                            type={'grid'}
                            category={'other'}
                            displayContentFormat={'article'}
                            displayContentTopic={'entertainment'}
                            articles={articles}
                            windowWidth={windowWidth}
                            specialOrigin={ArticleSpecialOrigin}
                        />
                    </GridContainer>
                ) : (
                    <ScrollableContainer>
                        <DisplayContentMemo
                            type={'scrollableSection'}
                            category={'other'}
                            displayContentFormat={'article'}
                            displayContentTopic={'entertainment'}
                            articles={articles}
                            windowWidth={windowWidth}
                            specialOrigin={ArticleSpecialOrigin}
                        />
                    </ScrollableContainer>
                )}
            </InnerContainer>
        </Container>
    );
};

export default EntertainmentArticleMenuPage;
