import React, { memo, useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { HelmetProvider } from "react-helmet-async";

import StartPage from "./components/pages/StartPage";
import ArticleFormat from "./components/contentFormats/ArticleFormat";
import Footer from "./components/NavbarFooter/Footer";
import GlobalStyles from "./components/styles/GlobalStyles";
import Navbar from "./components/NavbarFooter/NavBar";
import NotFoundPage from "./components/pages/NotFoundPage";
import SearchResults from "./components/pages/SearchResults";
import useWindowSize from "./components/utils/hooks/useWindowSize";
// import QuizFormat from './components/contentFormats/QuizFormat';
// import ArticleMenuPage from './components/pages/ArticleMenuPage';
// import QuizMenuPage from './components/pages/QuizMenuPage';
// import ReviewMenuPage from './components/pages/ReviewMenuPage';
// import ReviewFormat from './components/contentFormats/ReviewFormat';
// import RequestSizeError from './components/pages/RequestSizeError';
import { NavbarProvider } from "./components/utils/contexts/NavBarContext";
import UnderConstructionPage from "./components/pages/UnderConstructionPage";
import { articles } from "./components/data/Articles";
import AboutUs from "./components/legal/AboutUs";
import Contact from "./components/legal/Contact";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import TermsOfService from "./components/legal/TermsOfService";
import CookieBanner from "./components/legal/CookieBanner";
import {
  initFirebaseWithAnalytics,
  initFirebaseWithoutAnalytics,
} from "./Firebase";
import LifestyleArticleMenuPage from "./components/pages/LifestyleArticleMenuPage";
// import TechArticleMenuPage from './components/pages/TechArticleMenuPage';
import EntertainmentArticleMenuPage from "./components/pages/EntertainmentArticleMenuPage";
import { formatArticlePath } from "./components/utils/pathUtils";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${(props) =>
    props.windowHeight ? props.windowHeight - 100 + "px" : "100vh"};
  margin-top: 10px;
`;

const Content = styled.div`
  width: 100%;
  width: 100%;
  max-width: 1366px;
`;

// const VerticalLine = styled.div`
// position: absolute;
// top: 0;
// left: 50%;
// height: 83px;
// width: 5px;
// background-color: rgba(0, 0, 0, 0.5); // adjust color and opacity as needed
// pointer-events: none; // to ensure it doesn't interfere with clicking
// z-index: 9999; // to make sure it's on top
// opacity: 0.5;
// `;

const SearchResultsWrapper = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [searchQuery, setSearchQuery] = useState('');
  const page = Number(new URLSearchParams(location.search).get("page")) || 1;

  const query =
    new URLSearchParams(location.search).get("query")?.replace(/\+/g, " ") ||
    "";

  const setPage = useCallback(
    (page) => {
      navigate(`/search?query=${encodeURIComponent(query)}&page=${page}`);
    },
    [navigate, query]
  );

  // If I want to utilize the current search query
  // useEffect(() => {
  // setSearchQuery(query);
  // }, [query, location]);

  return <SearchResults searchQuery={query} page={page} setPage={setPage} />;
});

const getRandomPath = () => {
  const randomContent = articles[Math.floor(Math.random() * articles.length)];
  return formatArticlePath(randomContent);
};

const InnerApp = () => {
  const [windowHeight] = useWindowSize();

  useEffect(() => {
    const consentGiven =
      localStorage.getItem("firebaseAnalyticsConsent") === "true";
    if (consentGiven) {
      initFirebaseWithAnalytics();
    } else {
      initFirebaseWithoutAnalytics();
    }
  }, []);

  // const currentURL = window.location.href;
  // const maxSize = 2000;

  // if (currentURL.length > maxSize) {
  //     return <RequestSizeError />;
  // }

  return (
    <>
      {/* {!pathname.startsWith('/reviews/') || pathname === '/reviews/' ? ( */}
      <Navbar getRandomPath={getRandomPath} />
      {/* ) : ( */}
      {/* <Navbar noPlaceholder={true} getRandomPath={getRandomPath} /> */}
      {/* )} */}

      <AppContainer windowHeight={windowHeight}>
        <Content>
          <Routes>
            <Route path="/" element={<StartPage />} />
            {/* <Route path='/tech' element={<TechArticleMenuPage />} />
                        <Route path='/tech' element={<TechArticleMenuPage />} /> */}
            <Route path="/tech" element={<UnderConstructionPage />} />
            <Route
              path="/entertainment"
              element={<EntertainmentArticleMenuPage />}
            />
            <Route path="/lifestyle" element={<LifestyleArticleMenuPage />} />
            <Route path="/:category/:slug" element={<ArticleFormat />} />

            <Route path="/:slug" element={<ArticleFormat />} />
            {/* 
                        <Route
                            path='/articles/'
                            element={<ArticleMenuPage />}
                        /> 
                        <Route
                            path='/articles/tech'
                            element={<TechArticleMenuPage />}
                        />
                        <Route
                            path='/articles/entertainment'
                            element={<EntertainmentArticleMenuPage />}
                        />
                        <Route
                            path='/articles/lifestyle'
                            element={<LifestyleArticleMenuPage />}
                        />
                        <Route
                            path='/articles/:category/:slug'
                            element={<ArticleFormat />}
                        />

                        <Route
                            path='/articles/:slug'
                            element={<ArticleFormat />}
                        /> */}
            {/* <Route path='/reviews/' element={<ReviewMenuPage />} />
                        <Route
                            path='/reviews/:slug'
                            element={<ReviewFormat />}
                        /> */}
            {/* <Route path='/quizzes/' element={<QuizMenuPage />} />
                        <Route path='/quizzes/:slug' element={<QuizFormat />} /> */}
            {/* <Route
                            path='/request-size-error'
                            element={<RequestSizeError />}
                        /> */}
            <Route path="/search" element={<SearchResultsWrapper />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/coming-soon" element={<UnderConstructionPage />} />
          </Routes>
        </Content>
      </AppContainer>
      <Footer />
      <CookieBanner />
    </>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <GlobalStyles />
      {/* <VerticalLine /> */}
      <NavbarProvider>
        <Router>
          <InnerApp />
        </Router>
      </NavbarProvider>
    </HelmetProvider>
  );
};

export default App;
