import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import {
 AiOutlineRight,
 AiOutlineLeft,
 AiOutlineDoubleLeft,
 AiOutlineDoubleRight,
} from 'react-icons/ai';

import {
 articles,
 sortArticlesByDate,
 sortArticlesBySearchedRelevance,
} from '../data/Articles';
import CardPreview from '../components/CardPreview';
import DisplayContent from '../components/DisplayContent';
import useWindowSize from '../utils/hooks/useWindowSize';
import { helmet } from '../utils/config';
import { mobileVersion } from '../data/positionsGrid';

const Container = styled.div`
 box-sizing: border-box;
`;

const InnerContainer = styled.div`
 padding: 0px 20px;
 width: 100%;
 max-width: 1366px;
 margin-left: auto;
 margin-right: auto;
`;

const DisplayContentContainer = styled.div`
 max-width: calc(100% - 40px);
 margin-top: ${(props) => (props.windowHeight > 700 ? '0px' : '65px')};
 position: ${(props) =>
 props.windowHeight > 700 ? 'absolute' : 'relative'};
 bottom: 0px;
 padding-left: 10px;
 padding-right: 10px;
`;

const SectionTitle = styled.h2`
 font-size: 30.5px;
 margin: 20px 0;
 font-weight: 700;
`;

const Grid = styled.div`
 display: flex;
 flex-wrap: wrap;
 gap: 20px;
 margin-bottom: 20px;
 width: 100%;

 > * {
 flex-basis: calc(33.333% - 500px);
 }
`;

const SideGrid = styled.div`
 display: flex;
 flex-direction: column;
 gap: 10px;
 margin-bottom: 20px;
 width: 100%;
 align-items: flex-start;
 // background-color: black;
`;

const Pagination = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 7px;
 margin: 10px 0;
 margin-top: -6px;
`;

const PageNumber = styled.button`
 background: none;
 border: none;
 font-size: 16px;
 cursor: pointer;
 padding: 8px;
 border-radius: 50%;
 color: #888;
 transition: all 0.3s ease-out;
 &:hover {
 background: #ddd;
 transform: translateY(-2px);
 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 }
`;

const CurrentPageNumber = styled(PageNumber)`
 color: #000;
 font-weight: bold;
`;

const IconContainer = styled.button`
 background: none;
 border: none;
 cursor: pointer;
 transition: all 0.3s ease-out;
 &:hover {
 transform: translateY(-2px);
 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 }
 opacity: ${(props) => (props.visible ? '1' : '0')};
 pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const SearchResults = ({ searchQuery, page, setPage }) => {
 const [sortedArticles, setSortedArticles] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const [windowWidth, windowHeight] = useWindowSize();

 const location = useLocation();

 const correctSearchQuery = useCallback((query) => {
 if (query.length < 13 && query.includes('article')) return 'article';
 if (query.length < 10 && query.includes('quiz')) return 'quiz';
 if (query.length < 12 && query.includes('review')) return 'review';
 if (['quisses', 'quis'].includes(query)) return 'quiz';
 else if (
 ['artilces', 'atricles', 'atricle', 'aticle'].includes(query)
 ) {
 return 'article';
 } else if (
 [
 'revwie',
 'reiver',
 'revioew',
 'reivew',
 'reivue',
 'reive',
 ].includes(query)
 ) {
 return 'article';
 }
 return query;
 }, []);

 const resultsPerPage = 12;

 const filteredArticles = useMemo(() => {
 let lowerCaseSearchQuery = searchQuery
 .toLowerCase()
 .replace(/\+/g, ' ');

 if (!lowerCaseSearchQuery.includes(' ')) {
 lowerCaseSearchQuery = correctSearchQuery(lowerCaseSearchQuery);
 }

 let isTagSearch = false;
 let tagSearchValue = '';
 if (lowerCaseSearchQuery.startsWith('tag:')) {
 isTagSearch = true;
 tagSearchValue = lowerCaseSearchQuery.slice(4).trim();
 } else if (lowerCaseSearchQuery.startsWith('tag ')) {
 isTagSearch = true;
 tagSearchValue = lowerCaseSearchQuery.slice(3).trim();
 }

 let filteredArticles;
 if (isTagSearch) {
 filteredArticles = articles.filter((article) =>
 article.tags.includes(tagSearchValue)
 );
 } else {
 // perform normal search/filtering
 const searchQueryParts = lowerCaseSearchQuery.split(' ');
 const sectionQuery = searchQueryParts[0];
 let formatQuery = searchQueryParts[1] || 'null';
 if (formatQuery === 'quizzes') {
 formatQuery = 'quiz';
 } else if (formatQuery.endsWith('s')) {
 formatQuery = formatQuery.slice(0, -1);
 }

 filteredArticles = articles.filter(
 (article) =>
 (article.section.includes(sectionQuery) &&
 article.format === formatQuery) ||
 (article.section.includes(sectionQuery) &&
 formatQuery === 'content') ||
 article.title
 .toLowerCase()
 .includes(lowerCaseSearchQuery) ||
 article.tags.some((tag) =>
 tag.toLowerCase().includes(lowerCaseSearchQuery)
 ) ||
 article.author
 .toLowerCase()
 .includes(lowerCaseSearchQuery) ||
 article.format === lowerCaseSearchQuery // Add this line to search for article format
 );
 }
 if (searchQuery?.toLowerCase().includes('latest')) {
 return sortArticlesByDate(filteredArticles);
 }
 return sortArticlesBySearchedRelevance(filteredArticles);
 }, [searchQuery, correctSearchQuery]);

 useEffect(() => {
 setSortedArticles(filteredArticles);
 setIsLoading(false);
 }, [filteredArticles]);

 return (
 <Container>
 <Helmet>
 <title>Search Results for {searchQuery} | infoblis</title>
 <meta
 name='description'
 content={`Search results for ${searchQuery} on infoblis`}
 />
 <link
 rel='canonical'
 href={`https://infoblis.com${location.pathname}`}
 />
 <meta name='robots' content='noindex' />
 <meta
 property='og:title'
 content={`Search results for ${searchQuery} on infoblis`}
 />
 <meta
 property='og:description'
 content={`Search results for ${searchQuery} on infoblis`}
 />
 <meta
 name='keywords'
 content={'search results, search, results'}
 />
 <meta name='twitter:card' content='summary_large_image' />
 <meta name='twitter:site' content={helmet.TwitterHandle} />
 <meta
 name='twitter:title'
 content={`Search results for ${searchQuery} on infoblis`}
 />
 <meta
 name='twitter:description'
 content={`Search results for ${searchQuery} on infoblis`}
 />
 <script type='application/ld+json'>
 {(() => {
 let schema = {
 '@context': 'https://schema.org',
 '@type': 'SearchResultsPage',
 mainEntityOfPage: {
 '@type': 'WebPage',
 '@id': `https://infoblis.com${location.pathname}`,
 },
 headline: `Search results for ${searchQuery} on infoblis`,
 description: `Search results for ${searchQuery} on infoblis`,
 author: {
 '@type': 'Person',
 name: 'infoblis',
 },
 publisher: {
 '@type': 'Organization',
 name: 'infoblis',
 logo: {
 '@type': 'ImageObject',
 url: helmet.LogoUrl,
 },
 },
 };

 return JSON.stringify(schema);
 })()}
 </script>
 </Helmet>
 {!isLoading && (
 <InnerContainer>
 <SectionTitle>
 {sortedArticles.length > 0
 ? `${sortedArticles.length} Search Result${
 sortedArticles.length > 1 ? 's' : ''
 } for ${searchQuery}`
 : `No search results for ${searchQuery}`}
 </SectionTitle>
 {sortedArticles.length === 0 && (
 <DisplayContentContainer windowHeight={windowHeight}>
 <DisplayContent
 type={'scrollableSection'}
 category={'latest'}
 articles={articles}
 windowWidth={windowWidth}
 />
 </DisplayContentContainer>
 )}
 {windowWidth <= 850 ? (
 <SideGrid>
 {sortedArticles
 .slice(
 (page - 1) * resultsPerPage,
 page * resultsPerPage
 )
 .map((article) => (
 <CardPreview
 key={article.id}
 article={article}
 cardWidth={
 windowWidth < 707 ? '100%' : '650px'
 }
 cardHeight={
 windowWidth < 540
 ? '150px'
 : '200px'
 }
 imageWidth={'40%'}
 cardWidthNotDependentOnTitle={true}
 specialOrigin={'SearchResults'}
 isFeatured={false}
 cardType={'sideImage'}
 // minFontSize={15}
 titleSize={
 mobileVersion(
 windowWidth,
 'searchResultsSideCardTitleSize'
 )
 ? mobileVersion(
 windowWidth,
 'searchResultsSideCardTitleSize'
 )
 : '21.9px'
 }
 />
 ))}
 </SideGrid>
 ) : (
 <Grid>
 {sortedArticles
 .slice(
 (page - 1) * resultsPerPage,
 page * resultsPerPage
 )
 .map((article) => (
 <CardPreview
 key={article.id}
 article={article}
 cardWidth={'250px'}
 cardHeight={'375px'}
 imageHeight={'230px'}
 cardWidthNotDependentOnTitle={true}
 specialOrigin={'SearchResults'}
 cardType={'defaultDate'}
 titleSize={'20px'}
 />
 ))}
 </Grid>
 )}

 {sortedArticles.length > resultsPerPage && (
 <Pagination>
 <IconContainer
 visible={page > 2}
 onClick={() => setPage(1)}
 >
 <AiOutlineDoubleLeft />
 </IconContainer>
 <IconContainer
 visible={page > 1}
 onClick={() => setPage(page - 1)}
 >
 <AiOutlineLeft />
 </IconContainer>
 {[
 ...Array(
 Math.ceil(
 sortedArticles.length / resultsPerPage
 )
 ).keys(),
 ].map((pageNum) => {
 const pagesCount = Math.ceil(
 sortedArticles.length / resultsPerPage
 );
 let lowerLimit;
 let upperLimit;

 if (pagesCount <= 4) {
 lowerLimit = 0;
 upperLimit = pagesCount;
 } else {
 if (page <= 2) {
 lowerLimit = 0;
 upperLimit = 4;
 } else if (
 page > 2 &&
 page <= pagesCount - 2
 ) {
 lowerLimit = page - 2;
 upperLimit = page + 2;
 } else {
 lowerLimit = pagesCount - 4;
 upperLimit = pagesCount;
 }
 }

 if (
 pageNum >= lowerLimit &&
 pageNum < upperLimit
 ) {
 return pageNum + 1 === page ? (
 <CurrentPageNumber
 key={pageNum}
 onClick={() => setPage(pageNum + 1)}
 >
 {pageNum + 1}
 </CurrentPageNumber>
 ) : (
 <PageNumber
 key={pageNum}
 onClick={() => setPage(pageNum + 1)}
 >
 {pageNum + 1}
 </PageNumber>
 );
 } else {
 return null;
 }
 })}
 <IconContainer
 visible={
 page <
 Math.ceil(
 sortedArticles.length / resultsPerPage
 )
 }
 onClick={() => setPage(page + 1)}
 >
 <AiOutlineRight />
 </IconContainer>
 <IconContainer
 visible={
 page <
 Math.ceil(
 sortedArticles.length / resultsPerPage
 ) -
 2
 }
 onClick={() =>
 setPage(
 Math.ceil(
 sortedArticles.length /
 resultsPerPage
 )
 )
 }
 >
 <AiOutlineDoubleRight />
 </IconContainer>
 </Pagination>
 )}
 </InnerContainer>
 )}
 </Container>
 );
};

export default SearchResults;
