import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f9fafb;
    scroll-behavior: smooth;
  }

  a {
    color: inherit;
    text-decoration: none;
}

  // html {
  //   font-size: 16px;
  // }

  // @media (max-width: 1200px) {
  //   html {
  //     font-size: 10px;
  //   }

  // }

  // @media (max-width: 992px) {
  //   html {
  //     font-size: 14px;
  //   }
  // }

  // @media (max-width: 768px) {
  //   html {
  //     font-size: 13px;
  //   }
  // }
  `;

export default GlobalStyles;
