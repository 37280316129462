import { createContext, useState } from "react";

export const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [isNavbarShown, setIsNavbarShown] = useState(true);

  return (
    <NavbarContext.Provider
      value={{
        isNavbarShown,
        setIsNavbarShown,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
