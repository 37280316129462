import React from 'react';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

import { articles, formalTexts } from '../data/Articles';
import useWindowSize from '../utils/hooks/useWindowSize';
import { Body, Container, DisplayContentContainer, Header } from './AboutUs';
import { helmet } from '../utils/config';
import DisplayContent from '../components/DisplayContent';

const Contact = () => {
    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <Container windowHeight={windowHeight}>
            <Helmet>
                <title>{helmet.ContactUsTitle}</title>
                <meta
                    name='description'
                    content={helmet.ContactUsDescription}
                />
                <link
                    rel='canonical'
                    href={'https://infoblis.com/contact-us'}
                />
                <meta property='og:title' content={helmet.ContactUsOGTitle} />
                <meta
                    property='og:description'
                    content={helmet.ContactUsOGDescription}
                />
                <meta name='keywords' content={helmet.ContactUsKeywords} />
                <script type='application/ld+json'>
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'ContactPage',
                        headline: helmet.ContactUsTitle,
                        description: helmet.ContactUsDescription,
                        url: 'https://infoblis.com/contact-us',
                        publisher: {
                            '@type': 'Organization',
                            name: 'infoblis',
                            logo: {
                                '@type': 'ImageObject',
                                url: helmet.LogoUrl,
                            },
                        },
                    })}
                </script>
            </Helmet>
            <Header windowWidth={windowWidth}>Contact Us</Header>
            <Body
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formalTexts.contactUs),
                }}
            />

            <DisplayContentContainer windowHeight={windowHeight}>
                <DisplayContent
                    type={'scrollableSection'}
                    category={'latest'}
                    articles={articles}
                    windowWidth={windowWidth}
                    stickToBottom={true}
                />
            </DisplayContentContainer>
        </Container>
    );
};

export default Contact;
