import React, { memo } from 'react';
import styled from 'styled-components';
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaYoutube,
    FaTiktok,
} from 'react-icons/fa';
import { theme } from '../utils/config';
import { mobileVersion } from '../data/positionsGrid';
import useWindowSize from '../utils/hooks/useWindowSize';

const FooterContainer = styled.footer`
    background-color: ${theme.colors.primaryColor};
    background-image: ${theme.colors.primaryGradient};
    padding: 30px 10px;
    color: ${theme.colors.buttonText};
    width: 100%;
    text-align: center;
    position: relative;
`;

const FooterText = styled.p`
    margin: 0;
    font-size: 16px;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 31px;
    margin-top: 20px;
`;

const FooterLinkContainer = styled.div`
    max-width: 750px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-evenly;
    gap: ${(props) =>
        mobileVersion(props.windowWidth, 'footerLinkGap')
            ? mobileVersion(props.windowWidth, 'footerLinkGap')
            : '20px'};
`;

const FooterLink = styled.a`
    color: ${theme.colors.buttonText};
    text-decoration: none;

    @media (hover: hover) {
        &:hover {
            color: #718096;
        }
    }

    &.active {
        color: #718096;
    }
`;

const Footer = memo(() => {
    const [windowWidth] = useWindowSize();

    const year = new Date().getFullYear();
    return (
        <FooterContainer>
            <FooterLinkContainer windowWidth={windowWidth}>
                <FooterLink href='/about-us'>About Us</FooterLink>
                <FooterLink href='/contact'>Contact</FooterLink>
                <FooterLink href='/terms-of-service'>
                    Terms of Service
                </FooterLink>
                <FooterLink href='/privacy-policy'>Privacy Policy</FooterLink>
            </FooterLinkContainer>
            <FooterText>© {year} Infoblis. All rights reserved.</FooterText>
            <SocialIcons>
                <a
                    href='https://www.facebook.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaFacebook color='white' size='24' />
                </a>
                <a
                    href='https://www.twitter.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaTwitter color='white' size='24' />
                </a>
                <a
                    href='https://www.instagram.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaInstagram color='white' size='24' />
                </a>
                <a
                    href='https://www.youtube.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaYoutube color='white' size='24' />
                </a>
                <a
                    href='https://www.tiktok.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaTiktok color='white' size='22' />
                </a>
            </SocialIcons>
        </FooterContainer>
    );
});

export default Footer;
