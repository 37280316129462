import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import useMenuAnimation from "../utils/hooks/useMenuAnimation";
import { Link } from "react-router-dom";

const MenuContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : "16px")};
  left: 25px;
  padding: 10px;
  width: 120px;
  background-color: rgba(18, 44, 70, 0.955);
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow: hidden;
  transform-origin: top left;
  transform: scale(0);
  animation: ${(props) =>
    props.anim
      ? props.isClosing
        ? props.closingAnimation
        : props.animation
      : "none"};
`;

const MenuItem = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ddd;
  transition: background 0.3s ease;
`;

const MenuItemText = styled(({ ...props }) => <Link {...props} />)`
  font-size: 16px;
  color: inherit;
  font-weight: normal;

  @media (hover: hover) {
    &:hover {
      color: #718096;
    }
  }

  &.active {
    color: #718096;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 200vh;
  width: 100%;
  z-index: 998;
  background-color: transparent;
`;

const MenuIcon = styled.img`
  /* width: 24px;
  height: 24px; */
  cursor: pointer;
  display: block;
  margin-left: 5px;
  margin-right: -5px;
  filter: invert(1);
`;

const ContextMenu = ({
  src,
  items = [],
  top = null,
  contextMenuVisible,
  setContextMenuVisible,
}) => {
  const {
    animation,
    closingAnimation,
    startClosingAnimation,
    startAnimation,
    stopAnimation,
    anim,
    isClosing,
    stopClosingAnimation,
  } = useMenuAnimation();
  const toggleMenu = useCallback(() => {
    setContextMenuVisible((menuVisible) => !menuVisible);
  }, [setContextMenuVisible]);

  const handleDismiss = useCallback(() => {
    if (!isClosing) {
      startClosingAnimation();
      setTimeout(() => {
        setContextMenuVisible(false);
        stopClosingAnimation();
      }, 300);
    }
  }, [
    isClosing,
    setContextMenuVisible,
    startClosingAnimation,
    stopClosingAnimation,
  ]);

  useEffect(() => {
    if (contextMenuVisible && !isClosing) {
      startAnimation();
    } else {
      if (!isClosing) {
        stopAnimation();
      }
    }
  }, [
    contextMenuVisible,
    startAnimation,
    stopAnimation,
    stopClosingAnimation,
    isClosing,
  ]);

  const handleTouchStart = (e) => {
    const target = e.target.closest("A, DIV");
    if (target) {
      target.classList.add("active");
    }
  };

  const handleTouchEnd = (e) => {
    const target = e.target.closest("A, DIV");
    if (target) {
      target.classList.remove("active");
    }
  };

  return (
    <>
      <MenuIcon src={src} onClick={toggleMenu} width={24} height={24} />
      <div>
        {contextMenuVisible && !isClosing && (
          <Overlay onClick={handleDismiss} />
        )}
        {contextMenuVisible && (
          <MenuContainer
            top={top}
            animation={animation}
            closingAnimation={closingAnimation}
            anim={anim}
            isClosing={isClosing}
          >
            {items.map(({ text, link }) => (
              <MenuItem key={text} onClick={() => setContextMenuVisible(false)}>
                <MenuItemText
                  to={link}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  {text}
                </MenuItemText>
              </MenuItem>
            ))}
          </MenuContainer>
        )}
      </div>
    </>
  );
};

export default React.memo(ContextMenu);
