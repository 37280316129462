import {
  useState,
  useRef,
  useEffect,
  useContext,
  memo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { mobileVersion } from "../data/positionsGrid";
import { NavbarContext } from "../utils/contexts/NavBarContext";

import menuIcon from "../assets/menuIcon.webp";
import useWindowSize from "../utils/hooks/useWindowSize";
import ContextMenu from "../components/ContextMenu";
import SearchBar from "./SearchBar";
import { theme } from "../utils/config";

export const initialNavbarHeight = 90;
const navLinkWindowWidth = 620;

export const getNavbarHeight = (windowHeight, isMobile) => {
  const maxNavbarHeight = windowHeight * 0.23;
  const minNavbarHeight = 65;
  let finalNavbarHeight = isMobile
    ? initialNavbarHeight - 20
    : initialNavbarHeight;

  if (finalNavbarHeight > maxNavbarHeight) finalNavbarHeight = maxNavbarHeight;
  if (finalNavbarHeight < minNavbarHeight) finalNavbarHeight = minNavbarHeight;
  return finalNavbarHeight + "px";
};

// #1e2d49
// #1a263c
// #1c273c
// #272b39
// #232931
// #1a263c, #1a2438, #1a263c

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.primaryColor};
  background-image: ${theme.colors.primaryGradient};
  /* background-image: linear-gradient(90deg, #22313f, #1c2b3d, #1d2936); */
  padding: 10px;
  color: ${theme.colors.buttonText};
  position: ${(props) =>
    props.sticky || props.noPlaceholder
      ? "fixed"
      : "static"}; // Used to be static
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.18s ease-in-out;
  transform: translateY(${(props) => (props.showNavbar ? "0" : "-100%")});
  height: ${(props) => {
    return getNavbarHeight(props.windowHeight, props.isMobile);
  }};
`;

const NavPlaceholder = styled.nav`
  width: 100%;
  height: ${(props) => getNavbarHeight(props.windowHeight, props.isMobile)};
  background: transparent;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between; // this will make sure child components are evenly distributed horizontally
  align-items: center;
  max-width: 1280px;
  width: 100%;
  // background: blue;
`;

const NavLinksOuterContainer = styled.div`
  display: ${(props) =>
    props.isMobile && props.showSearchInput ? "none" : "flex"};
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 20px;
  /* background: cyan; */
`;
const NavLinkInnerContainer = styled.div`
  display: ${(props) =>
    props.isMobile && props.showSearchInput ? "none" : "flex"};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${(props) =>
    props.windowWidth > navLinkWindowWidth ? "500px" : "200px"};
  min-width: 150px;
  /* background: tomato; */
  @media (max-width: 450px) and (min-width: 400px) {
    max-width: 165px; // Adjust to your preference
  }
`;

const NavLink = styled(({ windowWidth, ...props }) => <Link {...props} />)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${theme.colors.buttonText};
  /* font-size: 20px; */
  font-size: ${(props) =>
    mobileVersion(props, "navLinkSize")
      ? mobileVersion(props, "navLinkSize")
      : "20px"};
  transition: color 0.1s ease-in-out;
  /* font-family: 'Montserrat', sans-serif;  */

  @media (hover: hover) {
    &:hover {
      color: #718096;
    }
  }

  &.active {
    color: #718096;
  }
`;

// eslint-disable-next-line
const LogoText = styled.h1`
  font-size: ${(props) =>
    mobileVersion(props, "navLogoSize")
      ? mobileVersion(props, "navLogoSize")
      : "26px"};
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
  color: #1f2e3e;
  font-weight: bold;
  display: ${(props) =>
    props.isMobile && props.showSearchInput ? "none" : "flex"};

  &::before {
    content: "info";
    color: ${theme.colors.logoPrimary};
  }

  &::after {
    content: "blis";
    /* color: #ffa500; */
    color: ${theme.colors.logoSecondary};
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.buttonText};
`;

const Navbar = memo(({ noPlaceholder = false, getRandomPath }) => {
  const scrollPos = useRef(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(10000);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [sticky, setSticky] = useState(window.pageYOffset === 0 ? false : true);
  const [isReloading, setIsReloading] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const [isMobile, setIsMobile] = useState(
    mobileVersion(windowWidth, "isMobile") ? true : false
  );
  const [randomPath, setRandomPath] = useState(getRandomPath());
  const { setIsNavbarShown } = useContext(NavbarContext);
  const navRef = useRef();

  useEffect(() => {
    setIsNavbarShown(showNavbar);
  }, [showNavbar, sticky, windowWidth, setIsNavbarShown]); // Removed window.pageYOffset

  useEffect(() => {
    const mockNavbar = document.getElementById("mock-navbar");
    if (mockNavbar) {
      setTimeout(() => {
        mockNavbar.classList.add("hidden");
      }, 100);
    }
  }, []);

  useEffect(() => {
    const scrollThreshold = 20;

    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      const scrollDiff = Math.abs(currentScrollPosition - lastScrollPosition);

      // Only change navbar visibility if scrolled more than the threshold
      if (scrollDiff > scrollThreshold) {
        if (currentScrollPosition === 0) {
          setShowNavbar(true);
          setSticky(false);
        } else if (!isReloading) {
          setShowNavbar(true);
          setSticky(true);
        } else if (currentScrollPosition <= initialNavbarHeight && sticky) {
          setShowNavbar(true);
        } else if (
          currentScrollPosition >= initialNavbarHeight &&
          currentScrollPosition < lastScrollPosition
        ) {
          setShowNavbar(true);
          setSticky(true);
        } else if (
          currentScrollPosition >= lastScrollPosition &&
          currentScrollPosition >= initialNavbarHeight
        ) {
          setShowNavbar(false);
          setContextMenuVisible(false);
        }
        setLastScrollPosition(currentScrollPosition);
      }
    };
    setTimeout(() => {
      handleScroll();
    }, 50);
    setTimeout(() => {
      setIsReloading(true);
    }, 100);
  }, [isReloading, lastScrollPosition, sticky]);

  useEffect(() => {
    if (mobileVersion(windowWidth, "isMobile")) {
      setIsMobile(true);
      return;
    }
    setIsMobile(false);
  }, [windowWidth]);

  useEffect(() => {
    const scrollThreshold = 20;
    const stickyTimeout = 500;

    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      // console.log(currentScrollPosition);
      const scrollDiff = Math.abs(currentScrollPosition - scrollPos.current);

      if (currentScrollPosition === 0) {
        // console.log('SETTING STICKY TO FALSE');
        setShowNavbar(true);
        setSticky(false);
      } else if (scrollDiff > scrollThreshold) {
        if (currentScrollPosition <= initialNavbarHeight && !sticky) {
          setShowNavbar(true);
        } else if (currentScrollPosition <= initialNavbarHeight && sticky) {
          setShowNavbar(true);
        } else if (!isReloading) {
          setShowNavbar(true);
          setTimeout(() => {
            setSticky(true);
          }, stickyTimeout);
        } else {
          setShowNavbar(currentScrollPosition <= scrollPos.current);
          setTimeout(() => {
            setSticky(true);
          }, stickyTimeout);
        }
        scrollPos.current = currentScrollPosition;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isReloading, sticky]);

  const handleTouchStart = useCallback((e) => {
    const target = e.target.closest("A, DIV");
    if (target) {
      target.classList.add("active");
    }
  }, []);

  const handleTouchEnd = useCallback((e) => {
    const target = e.target.closest("A, DIV");
    if (target) {
      target.classList.remove("active");
    }
  }, []);

  const handleClickRandom = () => {
    const newPath = getRandomPath();
    setRandomPath(newPath);
  };

  return (
    <>
      {sticky && !noPlaceholder && (
        <NavPlaceholder windowHeight={windowHeight} isMobile={isMobile} />
      )}
      <Nav
        ref={navRef}
        showNavbar={showNavbar}
        sticky={sticky}
        isMobile={isMobile}
        windowHeight={windowHeight}
        noPlaceholder={noPlaceholder}
      >
        <NavContent>
          {windowWidth <= navLinkWindowWidth && (
            <ContextMenu
              contextMenuVisible={contextMenuVisible}
              setContextMenuVisible={setContextMenuVisible}
              src={menuIcon}
              items={[
                { text: "Random", link: getRandomPath() },
                { text: "Lifestyle", link: "/lifestyle" },
                // { text: 'Lifestyle', link: '/articles/lifestyle' },
                // { text: 'Reviews', link: '/reviews' },
              ]}
              top={
                parseFloat(getNavbarHeight(windowHeight, isMobile)) / 1.5 + "px"
              }
            />
          )}
          <LogoLink to="/">
            <LogoText
              windowWidth={windowWidth}
              isMobile={isMobile}
              showSearchInput={showSearchInput}
            />
            {/* {windowWidth} */}
            {/* {', '} */}
            {/* {windowHeight} */}
          </LogoLink>

          <NavLinksOuterContainer
            isMobile={isMobile}
            showSearchInput={showSearchInput}
            windowWidth={windowWidth}
          >
            <NavLinkInnerContainer
              isMobile={isMobile}
              showSearchInput={showSearchInput}
              windowWidth={windowWidth}
            >
              <NavLink
                // to='/articles/tech'
                to="/tech"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                windowWidth={windowWidth}
              >
                Tech
              </NavLink>
              {windowWidth > navLinkWindowWidth && (
                <NavLink
                  // to='/articles/entertainment'
                  to="/entertainment"
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  windowWidth={windowWidth}
                >
                  Entertainment
                </NavLink>
              )}
              <NavLink
                // to='/articles/lifestyle'
                to="/lifestyle"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                windowWidth={windowWidth}
              >
                Lifestyle
              </NavLink>
              {/* {windowWidth > navLinkWindowWidth && (
 <NavLink
 to='/reviews'
 onTouchStart={handleTouchStart}
 onTouchEnd={handleTouchEnd}
 windowWidth={windowWidth}
 >
 Reviews
 </NavLink>
 )}
 <NavLink
 to='/quizzes'
 onTouchStart={handleTouchStart}
 onTouchEnd={handleTouchEnd}
 windowWidth={windowWidth}
 >
 Quizzes
 </NavLink> */}
              {windowWidth > navLinkWindowWidth && (
                <NavLink
                  to={randomPath}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  windowWidth={windowWidth}
                  onClick={handleClickRandom}
                >
                  Random
                </NavLink>
              )}
            </NavLinkInnerContainer>
          </NavLinksOuterContainer>

          <SearchBar
            handleTouchStart={handleTouchStart}
            handleTouchEnd={handleTouchEnd}
            isMobile={isMobile}
            showSearchInput={showSearchInput}
            setShowSearchInput={setShowSearchInput}
          />
        </NavContent>
      </Nav>
    </>
  );
});

export default Navbar;
