// PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

import useWindowSize from '../utils/hooks/useWindowSize';
import { helmet } from '../utils/config';
import { formalTexts } from '../data/Articles';
import { Body, Container, Header } from './AboutUs';

const TermsOfService = () => {
    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <Container windowHeight={windowHeight}>
            <Helmet>
                <title>{helmet.TermsOfServiceTitle}</title>
                <meta
                    name='description'
                    content={helmet.TermsOfServiceDescription}
                />
                <link
                    rel='canonical'
                    href={'https://infoblis.com/terms-of-service'}
                />
                <meta
                    property='og:title'
                    content={helmet.TermsOfServiceOGTitle}
                />
                <meta
                    property='og:description'
                    content={helmet.TermsOfServiceOGDescription}
                />
                <meta name='keywords' content={helmet.TermsOfServiceKeywords} />
                <script type='application/ld+json'>
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'WebPage',
                        headline: helmet.TermsOfServiceTitle,
                        description: helmet.TermsOfServiceDescription,
                        url: 'https://infoblis.com/terms-of-service',
                        publisher: {
                            '@type': 'Organization',
                            name: 'infoblis',
                            logo: {
                                '@type': 'ImageObject',
                                url: helmet.LogoUrl,
                            },
                        },
                    })}
                </script>
            </Helmet>
            <Header windowWidth={windowWidth}>Terms of Service</Header>
            <Body
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formalTexts.termsOfService),
                }}
            />
        </Container>
    );
};

export default TermsOfService;
