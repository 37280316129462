import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigWithAnalytics = {
    apiKey: 'AIzaSyDmp49QtK6kQlqC4RE08akPwbk701EEQbU',
    authDomain: 'infoblis.firebaseapp.com',
    projectId: 'infoblis',
    storageBucket: 'infoblis.appspot.com',
    messagingSenderId: '77768457410',
    appId: '1:77768457410:web:b4987da7fcea854a7f0f5b',
    measurementId: 'G-L9JSFY344N',
};
const firebaseConfigWithoutAnalytics = {
    apiKey: 'AIzaSyDmp49QtK6kQlqC4RE08akPwbk701EEQbU',
    authDomain: 'infoblis.firebaseapp.com',
    projectId: 'infoblis',
    storageBucket: 'infoblis.appspot.com',
    messagingSenderId: '77768457410',
    appId: '1:77768457410:web:b4987da7fcea854a7f0f5b',
};

// Initialize Firebase

let app, analytics;

export const initFirebaseWithAnalytics = () => {
    if (!app) {
        // Check if app is already initialized
        app = initializeApp(firebaseConfigWithAnalytics);
        analytics = getAnalytics(app);
    }
};

export const initFirebaseWithoutAnalytics = () => {
    if (!app) {
        // Check if app is already initialized
        app = initializeApp(firebaseConfigWithoutAnalytics);
    }
};

export const firebaseAnalyticsLogEvent = (eventName, eventParams = {}) => {
    if (analytics) {
        logEvent(analytics, eventName, eventParams);
    }
};
