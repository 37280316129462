import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import useWindowSize from '../utils/hooks/useWindowSize';
import {
    initFirebaseWithAnalytics,
    initFirebaseWithoutAnalytics,
} from '../../Firebase';

const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: ${(props) => (props.windowWidth < 720 ? 'column' : 'row')};
    opacity: 0;
    transition: opacity 0.5s ease-out;
    &.visible {
        opacity: 1;
    }
    @media (max-width: 720px) {
        font-size: 14px;
    }
`;

const BannerText = styled.span`
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    margin-bottom: ${(props) => (props.windowWidth < 720 ? '10px' : '0')};
    margin-right: 20px;

    a {
        font-weight: bold; // Bold text
        text-decoration: underline; // Underlined text
        color: white; // Same color as the text
    }
`;

const Button = styled.button`
    font-family: 'Nunito', sans-serif;
    background-color: #ffffff;
    border: none;
    color: #22313f;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
        background-color: #f2f2f2;
    }
    &:last-child {
        margin-left: 10px;
    }
    @media (max-width: 720px) {
        font-size: 14px;
        padding: 5px 10px;
    }
    @media (max-width: 400px) {
        font-size: 14px;
        padding: 5px 8px;
    }
`;

const CookieBanner = () => {
    const [windowWidth] = useWindowSize();
    const [isBannerVisible, setIsBannerVisible] = useState(false); // Default to false

    useEffect(() => {
        const consent = localStorage.getItem('firebaseAnalyticsConsent');
        if (consent === null) {
            setIsBannerVisible(true);
        } else if (consent === 'true') {
            initFirebaseWithAnalytics(); // Initialize analytics
        } else if (consent === 'false') {
            initFirebaseWithoutAnalytics(); // Don't initialize analytics
        }
    }, []);

    const handleButtonClick = (buttonName) => {
        if (buttonName === 'accept') {
            initFirebaseWithAnalytics(); // Initialize analytics
            localStorage.setItem('firebaseAnalyticsConsent', 'true');
        } else if (buttonName === 'deny') {
            initFirebaseWithoutAnalytics(); // Don't initialize analytics
            localStorage.setItem('firebaseAnalyticsConsent', 'false');
        }
        setIsBannerVisible(false); // Hide banner
    };

    return (
        isBannerVisible && (
            <CookieConsent
                onAccept={() => setIsBannerVisible(false)} // Hide banner on accept
                location='bottom'
                cookieName='cookieConsent'
                style={{
                    background: '#1d2936',
                    padding: '10px',
                    fontSize: '14px',
                }}
                buttonStyle={{ display: 'none' }}
                expires={150}
            >
                <BannerContainer
                    windowWidth={windowWidth}
                    className={isBannerVisible ? 'visible' : ''}
                >
                    <BannerText windowWidth={windowWidth}>
                        We use cookies for Google Analytics.
                        {' '}
                        <Link to='/privacy-policy'>Learn more</Link>.
                    </BannerText>
                    <div>
                        <Button onClick={() => handleButtonClick('accept')}>
                            Accept
                        </Button>
                        <Button onClick={() => handleButtonClick('deny')}>
                            Deny
                        </Button>
                    </div>
                </BannerContainer>
            </CookieConsent>
        )
    );
};

export default CookieBanner;
