import React, { memo, useCallback, useState } from 'react';
import { FaShareAlt, FaFacebook, FaTwitter, FaLink } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled, { css, keyframes } from 'styled-components';

import { firebaseAnalyticsLogEvent } from '../../Firebase';

const SocialShareWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 7px;
    /* background-color: rgba(113, 128, 150, 0.06);   */
    padding: 5px 10px; // Some padding around icons
    border-radius: 10px;
    /* opacity: 0.4; */
    margin-left: -13px;
`;

const slideFadeIn = keyframes`
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ShareMenu = styled.div`
    display: flex;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    margin-left: -4px;

    & > a {
        opacity: ${(props) => (props.open ? '1' : '0')};
        transform: ${(props) =>
            props.open ? 'translateX(0)' : 'translateX(-10px)'};
        transition: transform 0.3s, opacity 0.3s;
        animation: ${(props) =>
            props.open
                ? css`
                      ${slideFadeIn} 0.3s
                  `
                : 'none'};
    }
`;
const ShareButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12.5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    color: #5b5b5b;
    font-size: 0.8em;
    text-decoration: none;
    /* background: red; */
    height: 100%;
    width: 100%;

    /* &:first-child {
        margin-left: -10px;
    }

    &:last-child {
        margin-right: 0;
    } */
`;

const ShareFacebook = styled(ShareButton)`
    color: #5b5b5b; // Facebook blue color
    &:hover {
        /* background-color: #3b5998; */
    }
`;

const ShareTwitter = styled(ShareButton)`
    color: #1da1f2; // Twitter blue color
    /* padding: 2px; */
    &:hover {
        /* background-color: #1da1f2; */
    }
`;

const CopyMessage = styled.div`
    position: absolute;
    margin-bottom: 48px;
    color: #fff;
    padding: 4px 7px;
    font-size: 1.1em;
    opacity: 1;
    animation: fadeOut 0.25s ease-in-out forwards;
    animation-delay: 2s;
    background: rgba(91, 91, 91, 0.82);
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const ShareLinks = memo(({ url, format }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2250);
    };

    const handleClick = useCallback((message) => {
        firebaseAnalyticsLogEvent(message + '_' + format);
    }, [format]);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    return (
        <SocialShareWrapper>
            <ShareButton onClick={toggleMenu}>
                <FaShareAlt style={{ fontSize: '16px' }} />
            </ShareButton>
            <ShareMenu open={menuOpen}>
                <CopyToClipboard text={url} onCopy={handleCopy}>
                    <ShareButton onClick={() => handleClick('share_copy')}>
                        <FaLink style={{ fontSize: '16px' }} />
                        {isCopied && <CopyMessage>Copied!</CopyMessage>}
                    </ShareButton>
                </CopyToClipboard>
                <ShareFacebook
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => handleClick('share_facebook')}
                >
                    <FaFacebook
                        style={{
                            fontSize: '18.5px',
                        }}
                    />
                </ShareFacebook>
                <ShareTwitter
                    href={`https://twitter.com/share?url=${url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => handleClick('share_twitter')}
                >
                    <FaTwitter
                        style={{
                            fontSize: '18.5px',
                        }}
                    />
                </ShareTwitter>
            </ShareMenu>
        </SocialShareWrapper>
    );
});
