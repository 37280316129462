
// const gradients = {
// #1e2c3d,
// #222e 3b,
// #1e2c3d,
// #222e3b
// #2FD0C5, #2F8AD0, #2FD075
// #045F86, #1B7C9C, #2E97A3, #49AEB5
// #088a7a, #088181, #0C7472, #0E5E5C
// #FFC371, #FFA07A, #FA8072, #E9967A
// #FF6B6B, #FF7F50, #FFA07A, #FFC2B4
//#171e27 // #87CEEB, #6BBBDC, #519DC6
// #0D8ABC, #0A6D99, #095781
// };

export const theme = {
    colors: {
        primaryGradient: 'linear-gradient(90deg, #22313f, #1c2b3d, #1d2936)',
        primaryColor: '#1f2634',
        logoPrimary: '#fff',
        logoSecondary: '#ffa500',
        white: '#fff',
        veryLightGray: '#f9fafb',
        blueButton: '#3182ce',
        blueButtonHover: '#2b6cb0',
        buttonText: '#fff',
    },
    borderRadius: {
        small: '5px',
        medium: '10px',
        large: '15px',
    },
    helmet: {
        StartPageDescription:
            'Explore trending articles, reviews, and quizzes across various topics at infoblis.',
    },
};

export const helmet = {
    StartPageTitle: 'Latest Articles, Reviews, Quizzes | infoblis',
    StartPageDescription:
        'Explore trending articles, reviews, and quizzes across various topics at infoblis.',
    StartPageKeywords:
        'Latest Articles, Trending Reviews, Exciting Quizzes, infoblis',
    StartPageOGTitle: 'Latest Articles, Reviews, Quizzes | infoblis',
    StartPageOGDescription:
        'Immerse yourself in a world of trending articles, reviews, and quizzes across a myriad of topics.',

    AboutUsTitle: 'About Us | infoblis',
    AboutUsDescription: 'Learn more about infoblis, our mission, and our team.',
    AboutUsKeywords:
        'About Us, infoblis, Information, Articles, Reviews, Quizzes',
    AboutUsOGTitle: 'About Us | infoblis',
    AboutUsOGDescription:
        'Learn more about infoblis, our mission, and our team.',

    ContactUsTitle: 'Contact Us | infoblis',
    ContactUsDescription: 'Get in touch with infoblis team.',
    ContactUsKeywords:
        'Contact Us, infoblis, Information, Articles, Reviews, Quizzes',
    ContactUsOGTitle: 'Contact Us | infoblis',
    ContactUsOGDescription: 'Get in touch with infoblis team.',

    PrivacyPolicyTitle: 'Privacy Policy | infoblis',
    PrivacyPolicyDescription: 'Privacy Policy of infoblis.',
    PrivacyPolicyKeywords:
        'Privacy Policy, infoblis, Information, Articles, Reviews, Quizzes',
    PrivacyPolicyOGTitle: 'Privacy Policy | infoblis',
    PrivacyPolicyOGDescription: 'Privacy Policy of infoblis.',

    TermsOfServiceTitle: 'Terms of Service | infoblis',
    TermsOfServiceDescription: 'Terms of Service of infoblis.',
    TermsOfServiceKeywords:
        'Terms of Service, infoblis, Information, Articles, Reviews, Quizzes',
    TermsOfServiceOGTitle: 'Terms of Service | infoblis',
    TermsOfServiceOGDescription: 'Terms of Service of infoblis.',

    ArticleMenuPageTitle: 'Explore Our Featured Articles | infoblis',
    ArticleMenuPageDescription:
        'Delve into our featured articles and discover new insights on a variety of engaging topics.',
    ArticleMenuPageOGTitle: 'Explore Our Featured Articles | infoblis',
    ArticleMenuPageOGDescription:
        'Dive into a world of engaging content with our featured articles covering a wide array of topics.',
    ArticleMenuPageKeywords:
        'Latest Articles, Trending Reviews, Exciting Quizzes, infoblis',

    QuizMenuPageTitle: 'Explore Our Featured Quizzes | infoblis',
    QuizMenuPageDescription:
        'Discover new insights with our featured quizzes. Topics include history, science, pop culture, and more.',
    QuizMenuPageOGTitle: 'Explore Our Featured Quizzes | infoblis',
    QuizMenuPageOGDescription:
        'Immerse yourself in a variety of topics with our curated selection of quizzes.',
    QuizMenuPageKeywords:
        'History Quizzes, Science Quizzes, Pop Culture Quizzes, Infoblis Quizzes',

    ReviewMenuPageTitle: 'Expert Level Reviews on Trending Topics | infoblis',
    ReviewMenuPageDescription:
        'Stay updated with the latest expert reviews at infoblis. Dive into our comprehensive reviews on a wide array of topics and make informed decisions.',
    ReviewMenuPageOGTitle: 'Expert Reviews on Trending Topics | infoblis',
    ReviewMenuPageOGDescription:
        'Uncover in-depth analysis and expert opinions with our curated reviews covering trending topics.',
    ReviewMenuPageKeywords:
        'Latest Reviews, Expert Analysis, Comprehensive Review Articles, Trending Topics, infoblis',

    LogoUrl: 'https://infoblis.com/infoBlisLogoIcon.ico',
    TwitterHandle: '@infoblis',
};
