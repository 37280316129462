import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { articles, formalTexts } from '../data/Articles';
import useWindowSize from '../utils/hooks/useWindowSize';
import DisplayContent from '../components/DisplayContent';
import { Separator } from '../contentFormats/ReviewFormat';
import { Link } from 'react-router-dom';
import { helmet, theme } from '../utils/config';

export const Container = styled.div`
    max-width: 1280px;
    margin: 20px auto;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-height: ${(props) => props.windowHeight - 90 + 'px'};
    display: flex;
    flex-direction: column;
`;
export const Header = styled.h1`
    text-align: center;
    color: #1f2937; // Changed color to a more formal dark blue.
    font-size: 2.5rem;
    margin-bottom: 20px; // Added some margin to separate from the body.
    @media (min-width: 400px) {
        font-size: 2rem;
    }
    @media (min-width: 450px) {
        font-size: 2.1rem;
    }
    @media (min-width: 730px) {
        font-size: 2rem;
    }
    @media (min-width: 870px) {
        font-size: 2.5rem;
    }
`;
export const Body = styled.div`
    max-width: 800px;
    margin: 0 auto;
    /* margin-bottom: 50px; */

    word-wrap: break-word;
    font-size: 18px;
    line-height: 1.8;
    overflow-y: auto;
    color: #333;

    h2 {
        margin: 0px 0px -15px 0px;
        color: black;
    }
    h3 {
        margin: 0px 0px -5px 0px;
        color: black;
    }
`;

export const DisplayContentContainer = styled.div`
    max-width: calc(100% - 40px);
    margin-top: 40px; // Added margin to push the container to bottom.
    padding-left: 10px;
    padding-right: 10px;
    /* background: gold; */
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const WhatWeDoContainer = styled.section`
    margin: -16px auto 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 2rem;
        color: #333;
        margin-bottom: 20px;
    }

    .what-we-do-cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
    }
`;

const WhatWeDoCard = styled(Link)`
    display: block;
    background: ${theme.colors.white};
    border: none;
    border-radius: 9px;
    transition: all 0.35s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    text-align: center;
    max-width: 250px;
    border-radius: 10px;
    padding: 0px;
    text-decoration: none;
    color: inherit;
    background: white;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    /* border: 1px solid rgba(110, 110, 110, 0.2); */
    @media (hover: hover) {
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
            transform: scale(1.015);
        }
    }

    img {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px 5px 3px 3px;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
        color: #718096;
        padding: 0px 6px;
    }
`;

const AboutUs = () => {
    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <Container windowHeight={windowHeight}>
            <Helmet>
                <title>{helmet.AboutUsTitle}</title>
                <meta name='description' content={helmet.AboutUsDescription} />
                <link rel='canonical' href={helmet.AboutUsUrl} />
                <meta
                    property='og:title'
                    content={'https://infoblis.com/about-us'}
                />
                <meta
                    property='og:description'
                    content={helmet.AboutUsOGDescription}
                />
                <meta name='keywords' content={helmet.AboutUsKeywords} />
                <script type='application/ld+json'>
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'AboutPage',
                        headline: helmet.AboutUsTitle,
                        description: helmet.AboutUsDescription,
                        url: 'https://infoblis.com/about-us',
                        publisher: {
                            '@type': 'Organization',
                            name: 'infoblis',
                            logo: {
                                '@type': 'ImageObject',
                                url: helmet.LogoUrl,
                            },
                        },
                    })}
                </script>
            </Helmet>
            <Header windowWidth={windowWidth}>About Us</Header>
            <Body>{formalTexts.aboutUs.mainText}</Body>
            <Separator specifiedWidth={'200px'} />
            <WhatWeDoContainer>
                <h2>What We Do</h2>
                <div className='what-we-do-cards'>
                    {formalTexts.aboutUs.whatWeDo.map((card, index) => (
                        <WhatWeDoCard key={index} to={`/${card.type}`}>
                            <img
                                src={`https://picsum.photos/600/400?random${
                                    index + 1
                                }`}
                                alt={card.title}
                            />
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </WhatWeDoCard>
                    ))}
                </div>
            </WhatWeDoContainer>
            <DisplayContentContainer windowHeight={windowHeight}>
                <DisplayContent
                    type={'scrollableSection'}
                    category={'latest'}
                    articles={articles}
                    windowWidth={windowWidth}
                />
            </DisplayContentContainer>
        </Container>
    );
};

export default AboutUs;
