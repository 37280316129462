import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { articles, sortArticlesByPriority } from "../data/Articles";
import {
  getUpdatedPositions,
  isLocalStorageItemAvailable,
  mobileVersion,
  positions,
} from "../data/positionsGrid";
import CardPreview from "../components/CardPreview";
import DisplayContent from "../components/DisplayContent";
import useWindowSize from "../utils/hooks/useWindowSize";
import { helmet, theme } from "../utils/config";

// Fix Privacy, TOS, Firebase
// 10 articles: Tech/Entertainment/Lifestyle
// Create og:image to place in index.html

// Activate ServiceWorkers, generate site-xml, robots?

// Ad sense: Commented out in index.html, ArticleFormat

// Make well rounded down to 360px width / Final touch ups

// SEO:
// Images should be .webp
// Transparent images should be .png
// Images needs to be resized immediately,

// (node:16332) MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 11 open listeners added to [_0x4ea6b2]. Use emitter.setMaxListeners() to increase limit
export const ArticlePlaceholder = styled.div`
  width: 100%;
  height: ${(props) => props.windowHeight + 300 + "px"};
  z-index: 4;
  background-color: ${theme.colors.veryLightGray};
  background-color: ${theme.colors.white};
  position: absolute;
`;

export const Container = styled.div`
  box-sizing: border-box;
  background: ${theme.colors.veryLightGray};
  background: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: -10px;
  padding-bottom: 1px;
`;
export const InnerContainer = styled.div`
  padding: 0px 20px;
`;

export const GridContainer = styled.div``;

export const ScrollableContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const FeaturedSection = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: ${(props) => {
    return mobileVersion(props, "featuredSectionHeight")
      ? mobileVersion(props, "featuredSectionHeight")
      : "580px";
  }};
  margin-bottom: 40px;
  margin-top: 0px;
  padding: 0px 20px;

  > * {
    flex-basis: calc(33.333% - 500px);
  }
`;

export const FeaturedArticle = styled(CardPreview)`
  position: absolute;
  top: ${({ position }) => position && position.top};
  left: ${({ position }) => position && position.left};
  width: ${({ position }) => position && position.width};
  height: ${({ position }) => position && position.height};
`;

const StartPage = () => {
  const sortedArticles = useMemo(
    () => sortArticlesByPriority(articles),
    // eslint-disable-next-line
    [articles]
  );

  const featuredArticles = useMemo(
    () =>
      sortedArticles
        .filter((article) => article?.section?.includes("startPageFeatured"))
        .slice(0, positions.length), // Slice so that more than 8 featured articles can be picked
    [sortedArticles]
  );

  const [titleSizeReady, setTitleSizeReady] = useState(
    isLocalStorageItemAvailable("calculatedTitleSize") ? true : false
  );

  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTitleSizeReady(true);
    }, 20);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Container>
      <Helmet>
        <title>{helmet.StartPageTitle}</title>
        <meta name="description" content={helmet.StartPageDescription} />
        <link
          rel="canonical"
          href={`https://infoblis.com${useLocation().pathname}`}
        />
        <meta property="og:title" content={helmet.StartPageOGTitle} />
        <meta
          property="og:description"
          content={helmet.StartPageOGDescription}
        />
        <meta name="keywords" content={helmet.StartPageKeywords} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            headline: helmet.StartPageTitle,
            description: helmet.StartPageDescription,
            url: `https://infoblis.com${useLocation().pathname}`,
            publisher: {
              "@type": "Organization",
              name: "infoblis",
              logo: {
                "@type": "ImageObject",
                url: helmet.LogoUrl,
              },
            },
          })}
        </script>
      </Helmet>
      {!titleSizeReady && <ArticlePlaceholder windowHeight={windowHeight} />}
      <FeaturedSection windowWidth={windowWidth}>
        {featuredArticles.map((article, index) => {
          const updatedPositions = getUpdatedPositions(windowWidth);
          return (
            <FeaturedArticle
              key={article.id}
              article={article}
              cardType={updatedPositions[index].featuredCardType}
              isFeatured={true}
              position={updatedPositions[index]}
              cardWidth={updatedPositions[index].width}
              cardHeight={updatedPositions[index].height}
              imageHeight={updatedPositions[index].imageHeight}
              imageWidth={updatedPositions[index].imageWidth}
              titleSize={updatedPositions[index].titleSize}
              cardWidthNotDependentOnTitle={true}
              excerptSize={updatedPositions[index].excerptSize}
              disableExcerpt={updatedPositions[index].disableExcerpt}
              disableCard={updatedPositions[index].disableCard}
              windowWidth={windowWidth}
              specialOrigin={"StartPage"}
            />
          );
        })}
      </FeaturedSection>
      <InnerContainer>
        <ScrollableContainer>
          <DisplayContent
            type={"scrollableSection"}
            category={"latest"}
            articles={articles}
            displayContentFormat={"article"}
            windowWidth={windowWidth}
            specialOrigin={"StartPage"}
          />
        </ScrollableContainer>
        <ScrollableContainer>
          <DisplayContent
            type={"scrollableSection"}
            displayContentFormat={"article"}
            category={"popular"}
            displayContentTopic={"entertainment"}
            articles={articles}
            windowWidth={windowWidth}
            specialOrigin={"StartPage"}
          />
        </ScrollableContainer>
        {windowWidth > 680 ? (
          <GridContainer>
            <DisplayContent
              type={"grid"}
              category={"other"}
              articles={articles}
              windowWidth={windowWidth}
              specialOrigin={"StartPage"}
            />
          </GridContainer>
        ) : (
          <ScrollableContainer>
            <DisplayContent
              type={"scrollableSection"}
              category={"other"}
              articles={articles}
              windowWidth={windowWidth}
              specialOrigin={"StartPage"}
            />
          </ScrollableContainer>
        )}
      </InnerContainer>
    </Container>
  );
};

export default StartPage;
