import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { AiOutlineRight } from 'react-icons/ai';
import { FaChevronRight } from 'react-icons/fa';

import { sortArticlesByDate } from '../data/Articles';
import CardPreview from './CardPreview';
import useScrollHook from '../utils/hooks/useScrollHook';
import useWindowSize from '../utils/hooks/useWindowSize';
import { mobileVersion } from '../data/positionsGrid';
import { useNavigate } from 'react-router-dom';
import { firebaseAnalyticsLogEvent } from '../../Firebase';
import { theme } from '../utils/config';

const ScrollableContainer = styled.div`
    position: relative;
    margin: -20px; // Counteract the padding
    ${(props) =>
        props.stickToBottom &&
        `
 margin-top: auto;
 `}
`;

const ScrollableSection = styled.div`
    position: relative;
    display: flex;
    gap: ${(props) =>
        mobileVersion(props, 'scrollableGap')
            ? mobileVersion(props, 'scrollableGap')
            : '20px'};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    width: 100%;
    padding: 20px; // Room for card's hover effect
    min-height: 100px;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    cursor: grab;
`;

const ScrollArrow = styled.div`
    position: absolute;
    top: ${(props) =>
        mobileVersion(props, 'isMobile')
            ? 'calc(50% - 18.6px)'
            : 'calc(50% - 19.45px)'};
    /* top: calc(50% - 19.45px); */
    /* color: ${theme.colors.white}; */
    font-size: ${(props) =>
        mobileVersion(props, 'isMobile') ? '37px' : '38.9px'};
    cursor: pointer;
    z-index: 4;
    transition: opacity 0.3s;
    margin: 0px 4px;

    &:first-child {
        left: 0;
    }

    &:last-child {
        right: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: ${(props) => (mobileVersion(props, 'isMobile') ? '5%' : '12%')};
        left: 10%;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: gray;
        z-index: -1;
    }

    & > svg {
        fill: white;
    }

    ${(props) =>
        props.direction === 'left' && !props.visible ? 'display: none;' : ''}
    ${(props) =>
        props.direction === 'right' && !props.visible ? 'display: none;' : ''}
`;

const SectionTitle = styled.h2`
    font-size: ${(props) => {
        return mobileVersion(props, 'sectionTitle')
            ? mobileVersion(props, 'sectionTitle')
            : '1.8rem';
    }};
    margin: 20px 0px 10px 0px;
    margin-left: ${(props) => props.marginLeft || '0px'};
    margin-bottom: ${(props) => props.marginBottom || '10px'};
    font-weight: 700;
`;

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;

    > * {
        flex-basis: calc(33.333% - 500px);
    }
`;

const ShowMoreButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-weight: bold;
    margin-right: 25px;
    margin-left: ${(props) =>
        props.type === 'scrollable'
            ? '4px'
            : props.type === 'grid'
            ? '4px'
            : '4px'};
    white-space: nowrap;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        font-size: 25px;
        font-size: ${(props) =>
            mobileVersion(props, 'showMoreButtonIcon')
                ? mobileVersion(props, 'showMoreButtonIcon')
                : '30px'};
        border: 2px solid rgba(112, 112, 112, 0.9);
        border-radius: 50%;
        padding: 4px;

        svg {
            margin-left: 1px;
            margin-right: -1px;
        }
    }
`;

const ShowMoreButton = styled.div`
    font-size: ${(props) =>
        mobileVersion(props, 'showMoreButtonText')
            ? mobileVersion(props, 'showMoreButtonText')
            : '25px'};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-weight: bold;
    white-space: nowrap;
    /* border: 1px solid rgba(112, 112, 112, 0.6);
 border-radius: 5px; */
    /* padding: 20px 15px; */
    margin-left: 5px;
    background-color: transparent;

    @media (hover: hover) {
        &:hover {
            opacity: 0.8;
        }
    }

    &.active {
        opacity: 0.8;
    }
`;
const DisplayContent = ({
    type,
    displayContentFormat = 'mixed',
    displayContentTopic,
    displayContentTags,
    category,
    articles,
    currentArticleId,
    specialOrigin = null,
    isLoaded = true,
    stickToBottom = false,
}) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const navigate = useNavigate();

    const getFirstNumberOfArticles = (articles, number) => {
        return articles.slice(0, number);
    };

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const numberOfContentToShowScrollable = [
        'ArticleFormat',
        'QuizFormat',
        'ReviewFormat',
    ].includes(specialOrigin)
        ? 5
        : 12;

    const numberOfContentToShowGrid = 10;

    const sortedArticles = sortArticlesByDate(articles);

    const displayContentSortArticles = (format) => {
        return sortedArticles.filter(
            (article) =>
                article.format === format &&
                article.section.includes(category) &&
                article.id !== currentArticleId &&
                (!displayContentTopic
                    ? displayContentTags
                        ? article.tags.includes(displayContentTags)
                        : true
                    : article.topic.includes(displayContentTopic))
        );
    };

    // const categoryArticles =
    // displayContentFormat === 'quiz'
    // ? displayContentSortArticles('quiz')
    // : displayContentFormat === 'article'
    // ? displayContentSortArticles('article')
    // : displayContentFormat === 'review'
    // ? displayContentSortArticles('review')
    // : sortedArticles.filter(
    // (article) =>
    // article.section.includes(category) &&
    // article.id !== currentArticleId
    // );

    const categoryArticles =
        displayContentFormat === 'quiz'
            ? displayContentSortArticles('quiz')
            : displayContentFormat === 'article'
            ? displayContentSortArticles('article')
            : displayContentFormat === 'review'
            ? displayContentSortArticles('review')
            : sortedArticles.filter((article) => {
                  const matchTopic = displayContentTopic
                      ? article.topic.includes(displayContentTopic)
                      : true;
                  const matchTags = displayContentTags
                      ? article.tags.includes(displayContentTags)
                      : true;
                  return (
                      article.section.includes(category) &&
                      article.id !== currentArticleId &&
                      matchTopic &&
                      matchTags
                  );
              });
    const firstNumberOfScrollableArticles = useMemo(
        () =>
            getFirstNumberOfArticles(
                categoryArticles,
                numberOfContentToShowScrollable
            ),
        [categoryArticles, numberOfContentToShowScrollable]
    );
    const firstNumberOfGridArticles = useMemo(
        () =>
            getFirstNumberOfArticles(
                categoryArticles,
                numberOfContentToShowGrid
            ),
        [categoryArticles, numberOfContentToShowGrid]
    );

    const {
        scrollRef,
        leftArrowVisible,
        rightArrowVisible,
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        hasScrolled,
        setHasScrolled,
        handleScroll,
        handleMouseDownPrevious,
        handleMouseUpPrevious,
        handleMouseDownNext,
        handleMouseUpNext,
    } = useScrollHook();

    const handleCardClick = (e, article) => {
        if (window.getSelection().toString() === '') {
            if (hasScrolled) {
                e.preventDefault();
            } else {
                firebaseAnalyticsLogEvent(
                    'clicked_' +
                        article.format +
                        ([
                            'startpage',
                            'articlemenupage',
                            'quizmenupage',
                            'reviewmenupage',
                        ].includes(specialOrigin?.toLowerCase())
                            ? '_' + specialOrigin?.toLowerCase()
                            : '') +
                        '_displaycontent' +
                        '_' +
                        type
                );
            }
            setHasScrolled(false);
        }
    };

    const handleSearchSubmit = (query) => {
        query = query
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        navigate(`/search?query=${encodeURIComponent(query)}`);
        window.scrollTo(0, 0);
    };

    if (!isLoaded) {
        return null;
    }

    const SectionTitleIcon = () => {
        return (
            <SectionTitle
                windowWidth={windowWidth}
                marginLeft={stickToBottom ? '20px' : null} // When SectionTitleIcon is inside of the container, it has no margin
                marginBottom={stickToBottom ? '-10px' : null}
            >
                {capitalize(category)}{' '}
                {displayContentTopic
                    ? capitalize(displayContentTopic)
                    : displayContentTags
                    ? capitalize(displayContentTags)
                    : capitalize(
                          displayContentFormat === 'quiz'
                              ? 'quizzes'
                              : displayContentFormat === 'article'
                              ? 'articles'
                              : displayContentFormat === 'review'
                              ? 'reviews'
                              : 'content'
                      )}
                <AiOutlineRight
                    style={{
                        fontSize: '0.99rem',
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                        marginLeft: 3,
                        color: '#707070',
                    }}
                    onClick={() =>
                        handleSearchSubmit(
                            `${category} ${
                                displayContentFormat === 'mixed'
                                    ? 'content'
                                    : displayContentFormat
                            }`
                        )
                    }
                />
            </SectionTitle>
        );
    };

    switch (type) {
        case 'grid':
            return (
                <>
                    <SectionTitleIcon />
                    <Grid scrollable={false}>
                        {firstNumberOfGridArticles.map((article) => (
                            <CardPreview
                                key={article.id}
                                article={article}
                                cardType={article.cardType}
                                isFeatured={false}
                                onClick={(e) => handleCardClick(e, article)}
                                windowWidth={windowWidth}
                                specialOrigin={specialOrigin}
                                cardDisplayFormat={displayContentFormat}
                                displayType={'grid'}
                            />
                        ))}
                        {categoryArticles.length >
                            numberOfContentToShowGrid && (
                            <ShowMoreButtonContainer
                                type={'grid'}
                                windowWidth={windowWidth}
                            >
                                <ShowMoreButton
                                    type={'grid'}
                                    windowWidth={windowWidth}
                                    onClick={() =>
                                        handleSearchSubmit(
                                            `${category} ${
                                                displayContentFormat === 'mixed'
                                                    ? 'content'
                                                    : displayContentFormat
                                            }`
                                        )
                                    }
                                >
                                    <span>Show more</span>
                                    <div className='icon-container'>
                                        <FaChevronRight />
                                    </div>
                                </ShowMoreButton>
                            </ShowMoreButtonContainer>
                        )}
                    </Grid>
                </>
            );

        case 'scrollableSection': // stickToBottom makes it at the bottom of screen, SectionTitleIcon at top
            return (
                <>
                    {!stickToBottom && <SectionTitleIcon />}
                    <ScrollableContainer stickToBottom={stickToBottom}>
                        {stickToBottom && <SectionTitleIcon />}
                        <ScrollArrow
                            windowWidth={windowWidth}
                            direction='left'
                            visible={leftArrowVisible}
                            onMouseDown={handleMouseDownPrevious}
                            onMouseUp={handleMouseUpPrevious}
                        >
                            <FaChevronCircleLeft />
                        </ScrollArrow>
                        <ScrollableSection
                            ref={scrollRef}
                            className='scrollable-grid'
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                            onScroll={handleScroll}
                            windowWidth={windowWidth}
                        >
                            {firstNumberOfScrollableArticles.map((article) => (
                                <CardPreview
                                    key={article.id}
                                    article={article}
                                    isFeatured={false}
                                    onClick={(e) => handleCardClick(e, article)}
                                    windowWidth={windowWidth}
                                    windowHeight={windowHeight}
                                    specialOrigin={specialOrigin}
                                    cardDisplayFormat={displayContentFormat}
                                    cardType={'defaultDate'}
                                    cardWidth={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateWidth'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateWidth'
                                              )
                                            : '250px'
                                    }
                                    cardHeight={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateHeight'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateHeight'
                                              )
                                            : '375px'
                                    }
                                    imageHeight={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateImageHeight'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateImageHeight'
                                              )
                                            : '230px'
                                    }
                                    cardWidthNotDependentOnTitle={true}
                                    titleSize={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateTitleSize'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateTitleSize'
                                              )
                                            : '20px'
                                    }
                                    excerptSize={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateExcerptSize'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateExcerptSize'
                                              )
                                            : null
                                    }
                                    enableShorterExcerpt={
                                        mobileVersion(
                                            windowWidth,
                                            'defaultDateEnableShorterExcerpt'
                                        )
                                            ? mobileVersion(
                                                  windowWidth,
                                                  'defaultDateEnableShorterExcerpt'
                                              )
                                            : null
                                    }
                                    displayType={'scrollableSection'}
                                />
                            ))}
                            {categoryArticles.length >
                                numberOfContentToShowScrollable && (
                                <ShowMoreButtonContainer
                                    type={'scrollable'}
                                    windowWidth={windowWidth}
                                >
                                    <ShowMoreButton
                                        windowWidth={windowWidth}
                                        type={'scrollable'}
                                        onClick={() =>
                                            handleSearchSubmit(
                                                `${category} ${
                                                    displayContentFormat ===
                                                    'mixed'
                                                        ? 'content'
                                                        : displayContentFormat
                                                }`
                                            )
                                        }
                                    >
                                        <span>Show more</span>
                                        <div className='icon-container'>
                                            <FaChevronRight />
                                        </div>
                                    </ShowMoreButton>
                                </ShowMoreButtonContainer>
                            )}
                        </ScrollableSection>
                        <ScrollArrow
                            windowWidth={windowWidth}
                            direction='right'
                            visible={rightArrowVisible}
                            onMouseDown={handleMouseDownNext}
                            onMouseUp={handleMouseUpNext}
                        >
                            <FaChevronCircleRight />
                        </ScrollArrow>
                    </ScrollableContainer>
                </>
            );
        default:
            return null;
    }
};

export default DisplayContent;
