const formatToPath = (format) => {
    switch (format) {
        case 'article':
            return 'articles';
        case 'quiz':
            return 'quizzes';
        case 'review':
            return 'reviews';
        default:
            return 'articles';
    }
};

export const formatArticlePath = (article) => {
    const formatPath = formatToPath(article.format);
    let topicPath = '';
    if (article && article.topic && article.topic.length > 0) {
        topicPath = `${article.topic[0]}/`;
    }
    const slugPath = article.slug;
    return article.format === 'article'
        ? `/${topicPath}${slugPath}`
        : `/${formatPath}/${topicPath}${slugPath}`;
    // return `/${formatPath}/${topicPath}${slugPath}`;
};
