export const positions = [
    {
        index: 0,
        top: '10px',
        left: '1.5%',
        width: '48.8%',
        height: '400px',
        imageHeight: '319px',
        titleSize: '21px',
    },
    {
        index: 1,
        top: '10px',
        left: '51.8%',
        width: '23%',
        height: '190px',
        imageHeight: '250%',
        titleSize: '19.4px',
        // disableExcerpt: true,
        featuredCardType: 'textOnImage',
    },
    {
        index: 2,
        top: '10px',
        left: '76.3%',
        width: '23%',
        height: '190px',
        featuredCardType: 'textOnImage',
        titleSize: '19.4px',
    },
    {
        index: 3,
        top: '220px',
        left: '51.8%',
        width: '23%',
        height: '190px',
        imageHeight: '300%',
        titleSize: '19.4px',
        // disableExcerpt: true,
        featuredCardType: 'textOnImage',
    },
    {
        index: 4,
        top: '220px',
        left: '76.3%',
        width: '23%',
        height: '380px',
        imageHeight: '265px',
        titleSize: '19.4px',
    },
    {
        index: 5,
        top: '430px',
        left: '1.5%',
        width: '25.6%',
        height: '170px',
        featuredCardType: 'textOnImage',
        titleSize: '19.4px',
    },
    {
        index: 6,
        top: '430px',
        left: '28.6%',
        width: '46.2%',
        height: '170px',
        imageWidth: '40%',
        featuredCardType: 'sideImage',
        titleSize: '19.4px',
    },
];

export const getUpdatedPositions = (windowWidth) => {
    const updatedPositions = JSON.parse(JSON.stringify(positions));

    if (windowWidth > 1200) {
    } else if (windowWidth > 1000) {
        updatedPositions[6].imageWidth = '210px';

        updatedPositions[1].excerptSize = '14px';
        updatedPositions[3].excerptSize = '14px';
    } else if (windowWidth > 920) {
        updatedPositions[3].imageHeight = '840px';
        updatedPositions[1].imageHeight = '840px';

        updatedPositions[0].titleSize = '18px';
        updatedPositions[1].titleSize = '17px';
        updatedPositions[2].titleSize = '17px';
        updatedPositions[3].titleSize = '17px';
        updatedPositions[4].titleSize = '17px';
        updatedPositions[5].titleSize = '17px';

        // updatedPositions[1].disableExcerpt = true;
        // updatedPositions[3].disableExcerpt = true;
    } else if (windowWidth > 760) {
        updatedPositions[3].imageHeight = '840px';
        updatedPositions[1].imageHeight = '840px';

        updatedPositions[0].titleSize = '18px';
        updatedPositions[1].titleSize = '17px';
        updatedPositions[2].titleSize = '17px';
        updatedPositions[3].titleSize = '17px';
        updatedPositions[4].titleSize = '17px';
        updatedPositions[5].titleSize = '17px';

        // updatedPositions[1].disableExcerpt = true;
        // updatedPositions[3].disableExcerpt = true;
    } else if (windowWidth > 580) {
        updatedPositions[0].width = '47.75%';
        updatedPositions[2].width = '47.75%';
        updatedPositions[4].width = '47.75%';
        updatedPositions[5].width = '47.75%';
        updatedPositions[6].width = '47.75%';

        updatedPositions[0].height = '200px';
        updatedPositions[2].height = '200px';
        updatedPositions[5].height = '200px';

        updatedPositions[6].imageWidth = '42%';

        updatedPositions[0].imageHeight = '130px';

        updatedPositions[0].left = '1.5%';
        updatedPositions[2].left = '50.75%';
        updatedPositions[4].left = '50.75%';
        updatedPositions[5].left = '1.5%';
        updatedPositions[6].left = '1.5%';

        updatedPositions[5].top = '400px';
        updatedPositions[6].top = '218px';

        updatedPositions[0].titleSize = windowWidth > 650 ? '17px' : '16    px';
        updatedPositions[1].titleSize = windowWidth > 650 ? '17px' : '16px';
        updatedPositions[2].titleSize = windowWidth > 650 ? '17px' : '16px';
        updatedPositions[3].titleSize = windowWidth > 650 ? '17px' : '16px';
        updatedPositions[4].titleSize = windowWidth > 650 ? '17px' : '16px';
        updatedPositions[5].titleSize = windowWidth > 650 ? '17px' : '16px';
        updatedPositions[6].titleSize = windowWidth > 650 ? '17px' : '16px';

        updatedPositions[1].disableCard = true;
        updatedPositions[3].disableCard = true;
    } else if (windowWidth > 450) {
        updatedPositions[0].width = '97%';
        updatedPositions[2].width = '47.75%';
        updatedPositions[4].width = '47.75%';
        updatedPositions[5].width = '47.75%';

        updatedPositions[0].height = '220px';
        updatedPositions[2].height = '130px';
        updatedPositions[4].height = '270px';
        updatedPositions[5].height = '130px';

        updatedPositions[0].imageHeight = '195px';
        updatedPositions[4].imageHeight = '240px';

        updatedPositions[0].left = '1.5%';
        updatedPositions[2].left = '1.5%';
        updatedPositions[4].left = '50.75%';
        updatedPositions[5].left = '1.5%';

        updatedPositions[2].top = '245px';
        updatedPositions[4].top = '245px';
        updatedPositions[5].top = '385px';

        updatedPositions[0].titleSize = '17px';
        // updatedPositions[1].titleSize = '16px';
        updatedPositions[2].titleSize = '16px';
        // updatedPositions[3].titleSize = '16px';
        updatedPositions[4].titleSize = '16px';
        updatedPositions[5].titleSize = '16px';
        // updatedPositions[6].titleSize = '16px';

        updatedPositions[1].disableCard = true;
        updatedPositions[3].disableCard = true;
        updatedPositions[6].disableCard = true;
    } else if (windowWidth > 300) {
        updatedPositions[0].width = '97%';
        updatedPositions[2].width = '47.75%';
        updatedPositions[4].width = '47.75%';
        updatedPositions[5].width = '47.75%';

        updatedPositions[0].height = '200px';
        updatedPositions[2].height = '110px';
        updatedPositions[4].height = '230px';
        updatedPositions[5].height = '110px';

        updatedPositions[0].imageHeight = '170px';
        updatedPositions[4].imageHeight = '145px';

        updatedPositions[0].left = '1.5%';
        updatedPositions[2].left = '1.5%';
        updatedPositions[4].left = '50.75%';
        updatedPositions[5].left = '1.5%';
        updatedPositions[2].top = '225px';
        updatedPositions[4].top = '225px';
        updatedPositions[5].top = '345px';

        updatedPositions[0].titleSize = '15.5px';
        updatedPositions[2].titleSize = '14.5px';
        updatedPositions[4].titleSize = '14.5px';
        updatedPositions[5].titleSize = '14.5px';

        updatedPositions[1].disableCard = true;
        updatedPositions[3].disableCard = true;
        updatedPositions[6].disableCard = true;
    } else {
        updatedPositions[0].width = '660px';
        updatedPositions[0].left = '20px';
        updatedPositions[1].width = '315px';
        updatedPositions[1].left = '700px';
        updatedPositions[2].width = '315px';
        updatedPositions[2].left = '1035px';
        updatedPositions[3].width = '315px';
        updatedPositions[3].left = '700px';
        updatedPositions[4].width = '315px';
        updatedPositions[4].left = '1035px';

        updatedPositions[5].width = '350px';
        updatedPositions[5].left = '20px';
        updatedPositions[6].width = '625px';
        updatedPositions[6].left = '390px';

        return updatedPositions;
    }
    return updatedPositions;
};

export const mobileVersion = (props, option) => {
    if (!props.windowWidth && typeof props === 'number') {
        props = { windowWidth: props };
    }

    if (props.windowWidth <= 400) {
        switch (option) {
            case 'searchResultsSideCardTitleSize':
                return '15px';
            default:
        }
    }

    if (props.windowWidth <= 580) {
        switch (option) {
            case 'isMobile':
                return true;

            // Card formatting
            case 'cardMinWidth':
                return '160px';
            case 'cardHeight':
                return '200px';
            case 'imageHeight':
                return '140px';
            case 'excerpt':
                return '12.3px';
            case 'title':
                return '16px';

            // Articles
            case 'articleTitleSize':
                return 35;
            case 'sectionTitle':
                return '22px';
            case 'articleTitleMaxSize':
                return 34;
            case 'articleBodyH2Size':
                return '1.2em';
            case 'textOnImageBottom':
                if (props.windowWidth <= 450) return '4px';
                return '6px';

            case 'articleTOCPaddingClosed':
                return '3px 10px';
            case 'articleTOCMaxWidthClosed':
                return '210px';
            case 'articleTOCBorderRadiusClosed':
                return '18px';

            case 'articleFormatTitleSize':
                if (props?.titleLength > 30) {
                    return '28px';
                }
                return '32px';

            // Navbar
            case 'navLogoSize':
                return '20px';
            case 'navLinkSize':
                return '18px';

            // Footer
            case 'footerLinkGap':
                return '15px';

            case 'featuredSectionHeight':
                if (props.windowWidth <= 300) return '570px';
                else if (props.windowWidth <= 450) return '430px';
                return '490px';

            // ScrollableSection
            case 'showMoreButtonText':
                return '19px';
            case 'showMoreButtonIcon':
                return '22px';
            case 'scrollableGap':
                return '10px';

            // defaultDate cardType formatting
            case 'defaultDateWidth':
                return '190px';
            case 'defaultDateHeight':
                return 180 * 1.5 + 'px';
            case 'defaultDateImageHeight':
                return '130px';
            case 'defaultDateTitleSize':
                return '16.5px';
            case 'defaultDateExcerptSize':
                return '15px';
            case 'defaultDateEnableShorterExcerpt':
                return true;

            case 'searchResultsSideCardTitleSize':
                return '18px';

            default:

            // case 'featuredCardTypeText':
            // return '16.5px';
        }
    }

    if (props.windowWidth <= 760) {
        switch (option) {
            case 'cardHeight':
                return '250px';
            case 'cardMinWidth':
                return '200px';
            case 'excerpt':
                if (props.specialOrigin === 'SearchResults') return '16px';
                return '14.5px';
            case 'title':
                return '20px';
            case 'navLogoSize':
                return '24px';
            case 'showMoreButtonText':
                return '22px';
            case 'showMoreButtonIcon':
                return '27px';
            case 'defaultDateTitleSize':
                return '17px';

            case 'searchResultsSideCardTitleSize':
                return '21px';

            // Articles
            case 'articleTitleMaxSize':
                return 37;

            default:
        }
    }

    if (props.windowWidth <= 1000) {
        switch (option) {
            case 'showMoreButtonText':
                return '24px';
            case 'showMoreButtonIcon':
                return '29px';

            case 'articleFormatTitleSize':
                if (props?.titleLength > 30) {
                    return '30px';
                }
                return '34px';

            default:
        }
    }

    return null;
};

export function isLocalStorageItemAvailable(prefix, suffix = '') {
    try {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (
                key.startsWith(prefix) &&
                (suffix ? key.endsWith(suffix) : true)
            ) {
                return true;
            }
        }
        return false;
    } catch (e) {
        return false;
    }
}
