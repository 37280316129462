import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import useWindowSize from './useWindowSize';

const videoMaxWidth = 650;

const Video = styled.div`
    width: 100%;
    max-width: ${videoMaxWidth + 'px'};
    margin: ${(props) =>
        props.specifiedMargin ? props.specifiedMargin : '25px auto'};
    position: relative;
    max-height: ${(props) =>
        props.windowWidth * 0.45 < 350
            ? props.windowWidth * 0.45 + 'px'
            : '350px'};
    min-height: ${(props) =>
        props.windowWidth - props.offset < videoMaxWidth
            ? (props.windowWidth - props.offset - 16) * (9 / 16) + 'px'
            : videoMaxWidth * (9 / 16) + 'px'};
    height: auto;

    & > iframe {
        border-radius: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: ${(props) => (props.isLoaded ? '1' : '0')};
        transition: opacity 0.3s;
    }
`;

// Skeleton styles
const SkeletonLoader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(221, 221, 221, 0.2);
    animation: ${keyframes`
        0% { background-position: 100% 50%; }
        100% { background-position: 0 50%; }
    `} 1.5s ease infinite;
`;

const extractVideoId = (url) => {
    if (!url) return null;
    const urlParts = url.split('watch?v=');
    if (urlParts.length < 2) {
        return null; // URL does not have a valid format
    }
    const videoId = urlParts[1].split('&')[0]; // In case there are other parameters after the video ID
    return videoId;
};

const useYouTubeEmbed = (
    initialVideoId,
    offset = 0,
    specifiedMargin = null
) => {
    const [videoId, setVideoId] = useState(
        extractVideoId(initialVideoId) || ''
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [windowWidth] = useWindowSize();

    const videoEmbed = videoId ? (
        <Video
            windowWidth={windowWidth}
            isLoaded={isLoaded}
            offset={offset}
            specifiedMargin={specifiedMargin}
        >
            <SkeletonLoader isLoaded={isLoaded} />
            <iframe
                onLoad={() => setIsLoaded(true)}
                src={`https://www.youtube.com/embed/${videoId}`}
                title='YouTube video player'
                frameBorder='0'
                allowFullScreen
            ></iframe>
        </Video>
    ) : null;

    return [videoEmbed, setVideoId];
};

export default useYouTubeEmbed;
