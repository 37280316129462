import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../utils/hooks/useWindowSize';
import { formatArticlePath } from '../utils/pathUtils';

const RelatedArticlesWrapper = styled.div`
    max-width: 800px;
    font-size: 18.8px;
    font-weight: bold;
    line-height: 1.67;
    margin: 10px auto;
`;

const RelatedArticleLink = styled.a`
    display: block;
    font-size: 16.6px;
    color: #0070bb;
    /* margin-bottom: 5px; */
    margin-left: 30px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:first-child {
        margin-top: 2px;
        margin-bottom: 5px;
    }
`;

const RelatedArticles = ({ article, articles }) => {
    const [windowWidth] = useWindowSize();
    // console.log('HEREHRE');

    const relatedArticleIds = article.relatedArticlesId.slice(0, 2); // Only take the first 2 IDs
    const relatedArticles = articles.filter((item) =>
        relatedArticleIds.includes(item.id)
    );

    if (relatedArticles.length === 0) {
        return null;
    }

    return (
        <RelatedArticlesWrapper windowWidth={windowWidth}>
            Related:
            {relatedArticles.map((relatedArticle) => (
                <RelatedArticleLink
                    key={relatedArticle.id}
                    href={formatArticlePath(relatedArticle)}
                >
                    {relatedArticle.title}
                </RelatedArticleLink>
            ))}
        </RelatedArticlesWrapper>
    );
};

export default RelatedArticles;
