import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../utils/hooks/useWindowSize';

// Function to handle font size dynamically
// const fontSizeScale = (windowWidth, sizes) => {
//     const breakpoints = [400, 450, 580, 730, 870];
//     for (let i = 0; i < breakpoints.length; i++) {
//         if (windowWidth < breakpoints[i]) {
//             return sizes[i];
//         }
//     }
//     return sizes[sizes.length - 1];
// };
// font-size: ${(props) => fontSizeScale(props.windowWidth, ['2.73rem', '3rem', '3.5rem', '4.5rem', '5rem', '5.5rem'])};

const ConstructionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
`;

const ComingSoonTitle = styled.h1`
    font-size: ${(props) =>
        props.windowWidth < 400
            ? '2.73rem'
            : props.windowWidth < 450
            ? '3rem'
            : props.windowWidth < 580
            ? '3.5rem'
            : props.windowWidth < 730
            ? '4.5rem'
            : props.windowWidth < 870
            ? '5rem'
            : '5.5rem'};
    font-weight: 700;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    margin-top: ${(props) =>
        props.windowWidth < 450
            ? '50px'
            : props.windowWidth < 600
            ? '100px'
            : props.windowWidth < 800
            ? '150px'
            : '200px'};
`;

const ComingSoonMessagePartOne = styled.p`
    font-size: ${(props) =>
        props.windowWidth < 400
            ? '1.2rem'
            : props.windowWidth < 450
            ? '1.3rem'
            : props.windowWidth < 550
            ? '1.5rem'
            : props.windowWidth < 580
            ? '1.6rem'
            : props.windowWidth < 610
            ? '1.65rem'
            : props.windowWidth < 660
            ? '1.7rem'
            : props.windowWidth < 730
            ? '1.46rem'
            : props.windowWidth < 800
            ? '1.65rem'
            : props.windowWidth < 870
            ? '1.78rem'
            : '1.94rem'};
    font-family: 'Nunito', sans-serif;
    text-align: center;
`;

const ComingSoonMessagePartTwo = styled(ComingSoonMessagePartOne)`
    margin-top: -15px;
`;

const ReturnLink = styled.a`
    display: inline-block;
    font-size: ${(props) =>
        props.windowWidth < 400
            ? '0.85rem'
            : props.windowWidth < 450
            ? '0.9rem'
            : props.windowWidth < 600
            ? '0.9rem'
            : props.windowWidth < 730
            ? '1rem'
            : props.windowWidth < 870
            ? '1.1rem'
            : '1.2rem'};
    text-decoration: none;
    color: #1f2937;
    border: 1px solid #4b5563;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: ${(props) =>
        props.windowWidth < 500
            ? '10px'
            : props.windowWidth < 900
            ? '15px'
            : '20px'};
    transition: background-color 0.25s ease, color 0.25s ease;
    font-family: 'Nunito', sans-serif;
    text-shadow: 1px 1px #d1d5db;

    &:hover {
        background-color: #1f2937;
        color: #f9fafb;
    }
`;

const UnderConstructionPage = () => {
    const [windowWidth] = useWindowSize();
    return (
        <ConstructionContainer>
            <ComingSoonTitle windowWidth={windowWidth}>
                Coming Soon
            </ComingSoonTitle>
            {windowWidth < 660 ? (
                <>
                    <ComingSoonMessagePartOne windowWidth={windowWidth}>
                        This page is under construction.
                    </ComingSoonMessagePartOne>
                    <ComingSoonMessagePartTwo windowWidth={windowWidth}>
                        Please check back later!
                    </ComingSoonMessagePartTwo>
                </>
            ) : (
                <ComingSoonMessagePartOne windowWidth={windowWidth}>
                    This page is under construction. Please check back later!
                </ComingSoonMessagePartOne>
            )}
            <ReturnLink href='/' windowWidth={windowWidth}>
                Return to Home
            </ReturnLink>
        </ConstructionContainer>
    );
};

export default UnderConstructionPage;
