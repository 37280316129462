// import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
// import DOMPurify from 'dompurify';
// import Sticky from 'react-stickynode';

// import { useFontSize } from '../utils/hooks/useFontSize';
// import useWindowSize from '../utils/hooks/useWindowSize';
// import { NavbarContext } from '../utils/contexts/NavBarContext';
// import { articles, convertDateStringToProperFormat } from '../data/Articles';
// import DisplayContent from '../components/DisplayContent';
// import NotFoundPage from '../pages/NotFoundPage';
// import {
//     isLocalStorageItemAvailable,
//     mobileVersion,
// } from '../data/positionsGrid';
// import {
//     ArrowTag,
//     AuthorDate,
//     AuthorName,
//     BodyImage,
//     ImageCaption,
//     ImageContainer,
//     ImageWrapper,
//     Tag,
//     TagContainer,
// } from './ArticleFormat';
// import { getNavbarHeight } from '../NavbarFooter/NavBar';
// import { ShareLinks } from '../components/ShareLinks';
// import useYouTubeEmbed from '../utils/hooks/useYouTubeEmbed';
// import { theme, helmet } from '../utils/config';

// const widthPCPBecomeColumn = 600;

// const Container = styled.div`
//     max-width: 1280px;
//     margin: 40px auto;
//     margin-top: -10px;
//     margin-bottom: 0px;
//     padding: 20px;
//     box-sizing: border-box;
//     width: 100%;
//     background: white;
//     border-radius: 5px;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//     min-height: ${(props) => props.windowHeight + 300 + 'px'};
// `;
// const ArticlePlaceholder = styled.div`
//     width: 97%;
//     height: ${(props) => props.windowHeight + 300 + 'px'};
//     z-index: 4;
//     background-color: white;
//     position: absolute;
// `;

// const DisplayContentContainer = styled.div`
//     max-width: 1100px;
//     margin: 0 auto;
//     margin-bottom: 10px;
// `;

// const TitleWrapper = styled.div`
//     max-width: 800px;
//     margin: 0 auto;
//     margin-top: ${(props) => {
//         if (props.navbarHeight < 100) {
//             return props.navbarHeight - 5 + 'px';
//         }
//         return props.navbarHeight ? props.navbarHeight + 'px' : '103px';
//     }};
// `;

// const Header = styled.h1`
//     margin-bottom: 15px;
//     margin-top: 0px;
//     font-size: ${(props) =>
//         props.calculatedTitleSize ? props.calculatedTitleSize : '40px'};
// `;

// const TableOfContentsContainer = styled.div`
//     position: fixed;
//     top: ${(props) =>
//         props.isNavbarShown ? props.navbarHeight + 10 + 'px' : '10px'};
//     transition: all 0.18s ease-in-out;
//     left: ${(props) =>
//         ((props.windowWidth > 1320 ? 1320 : props.windowWidth) - 800) / 2 / 2 -
//         25 -
//         90 +
//         (props.windowWidth > 1302 ? -6 : -0) +
//         'px'};
//     overflow: auto;
//     max-width: 180px;
//     z-index: 3;
//     padding: 10px;
//     box-shadow: 0 0 3px rgba(0, 0, 0, 0.15); // Add a shadow for better separation
//     ::-webkit-scrollbar {
//         display: none; // Hides scrollbar in Chrome, Safari and Opera
//     }
//     scrollbar-width: none; // Firefox
//     border-radius: 5px;
//     background: white;
// `;

// const TocItem = styled.div`
//     margin: 10px 0;
//     margin-left: 8px;
//     cursor: pointer;
//     color: #718096;
//     &:hover {
//         color: #4a5568;
//     }
// `;

// const ReviewContainer = styled.div`
//     max-width: 800px;
//     margin: auto;
//     width: 100%;
//     height: 100%;
// `;

// const TitleHighlightContainer = styled.div`
//     display: ${(props) => (props.windowWidth < 500 ? '' : 'flex')};
//     align-items: center;
//     /* margin-top: -15px; */
//     margin-bottom: 4px;
//     /* background: green; */
// `;
// const ReviewItemTitle = styled.h2`
//     margin-bottom: ${(props) => (props.isCompactVersion ? ' -20px ' : ' 0px ')};
//     margin-top: ${(props) => (props.isCompactVersion ? '0px' : '10px ')};
//     text-align: ${(props) =>
//         props.isCompactVersion ? 'center' : 'flex-start'};

//     font-size: ${(props) => {
//         // return '2em';
//         if (!props.isCompactVersion) {
//             if (props.windowWidth < 500) {
//                 return '1.7em';
//             }
//             if (props.windowWidth < 530) {
//                 return '1.64em';
//             }
//             if (props.windowWidth < 551) {
//                 return '1.8em';
//             }
//             if (props.windowWidth < 580) {
//                 return '1.87em';
//             }

//             return '2em';
//         }
//         return props.windowWidth < widthPCPBecomeColumn
//             ? '1.71em'
//             : props.windowWidth < 733
//             ? '1.83em'
//             : '2em';
//     }};
//     /* background: gold; */
// `;

// // const HighlightDivider = styled.div`
// // position: absolute;
// // width: ${(props) => (props.width ? props.width : '0px')};
// // height: 2px;
// // background-color: #455a64;
// // left: ${(props) => (props.left ? props.left : '0px')};
// // margin-top: 11px;
// // `;

// const ReviewHighlight = styled.h3`
//     font-size: ${(props) => {
//         // return '1.54em';
//         if (!props.isCompactVersion) {
//             if (props.windowWidth < 500) {
//                 return '1.4em';
//             }
//             if (props.windowWidth < 530) {
//                 return '1.4em';
//             }
//             if (props.windowWidth < 551) {
//                 return '1.45em';
//             }
//             if (props.windowWidth < 580) {
//                 return '1.5em';
//             }
//             return '1.54em';
//         }
//         if (props.highlight.length > 29) return '1.53em';
//         if (props.highlight.length > 27) return '1.64em';
//         if (props.highlight.length > 25) return '1.7em';
//         if (props.highlight.length > 23) return '1.84em';
//         if (props.highlight.length > 20) return '2.01em';
//         if (props.highlight.length > 18) return '2.1em';
//         return '2.15em';
//     }};
//     font-weight: 300;
//     font-family: ${(props) =>
//         props.isCompactVersion
//             ? 'Dancing Script, cursive'
//             : 'Montserrat, san-serif'};

//     margin: ${(props) =>
//         props.isCompactVersion
//             ? 'auto 0px -20px -20px'
//             : props.windowWidth < 500
//             ? '-5px 0px 0px 0px'
//             : '13px 0px 0px 0px'};
//     margin-left: ${(props) => {
//         if (props.isCompactVersion) return '-20px';
//         return props.windowWidth < 400
//             ? '6px'
//             : props.windowWidth < 460
//             ? '8px'
//             : props.windowWidth < 500
//             ? '10px'
//             : props.windowWidth < 550
//             ? '13px'
//             : props.windowWidth < 600
//             ? '15px'
//             : props.windowWidth < 733
//             ? '15px'
//             : '15px';
//     }};
//     color: #67808c;
//     color: #455a64;
//     opacity: 1;
//     text-align: ${(props) =>
//         props.isCompactVersion ? 'center' : 'flex-start'};
//     /* background: tomato; */
// `;

// const ReviewItemContainer = styled.div`
//     max-width: 800px;
//     border-radius: 5px;
//     margin-bottom: 5px;
// `;

// const ReviewItemDescriptionContainer = styled.div`
//     width: 100%;
//     ${(props) =>
//         props.isCompactVersion &&
//         `
//  display: grid;
//  grid-template-columns: 0.6fr 0.401fr;
//  gap: 20px;
//  margin-bottom: 8px;
// `}
// `;

// const ReviewItemDescription = styled.div`
//     max-width: 800px;
//     margin: ${(props) => (props.isCompactVersion ? '0px' : '0px auto')};
//     word-wrap: break-word;
//     font-size: 19px;
//     color: #4a5568;
//     line-height: 1.8;

//     h2 {
//         margin: 0px;
//         margin-top: 10px;
//         color: black;
//         font-size: 1.5em;
//     }

//     h3 {
//         margin: 0px;
//         margin-top: 15px;
//         margin-bottom: 0px;
//         color: black;
//         font-size: 1.17em;
//     }

//     p {
//         margin-top: 0px;
//         margin-bottom: 8px;
//         font-size: ${(props) => {
//             if (!props.isCompactVersion || props.isDescriptionShort)
//                 return '1em';
//             if (props.windowWidth > 850) return '1em';
//             if (props.windowWidth > 800) return '0.94em';
//             if (props.windowWidth > 765) return '0.91em';
//             if (props.windowWidth > 742) return '0.89em';
//             if (props.windowWidth > 730) return '0.86em';
//             if (props.windowWidth > 700) return '1em';
//         }};
//     }

//     .image-container {
//         text-align: center;
//         // margin-top: 10px;
//         margin-bottom: 5px;
//         margin-left: 0;
//         margin-right: 0;

//         img {
//             width: 100%;
//             max-height: ${(props) => {
//                 if (!props.isCompactVersion)
//                     return props.windowWidth * 0.45 < 350
//                         ? props.windowWidth * 0.45 + 'px'
//                         : '350px';
//                 return props.windowWidth * 0.45 < 350
//                     ? props.windowWidth * 0.5 + 'px'
//                     : '380px';
//             }};
//             min-height: 200px;
//             height: auto;
//             object-fit: cover;
//             border-radius: 10px;
//             margin-top: ${(props) => (props.isCompactVersion ? '7px' : '1px')};
//         }
//     }

//     .image-caption {
//         line-height: 1;
//         font-size: 0.9rem;
//         color: #718096;
//         font-style: italic;
//         display: block;
//         margin-top: -1px;
//         margin-bottom: 0px;
//     }
// `;

// const ProConOverContainer = styled.div`
//     display: ${(props) => (props.isCompactVersion ? 'block' : 'flex')};
//     justify-content: ${(props) =>
//         props.isCompactVersion ? 'initial' : 'center'};
//     border-radius: 5px;
// `;
// const ProConContainer = styled.div`
//     display: flex;
//     align-items: flex-start;
//     justify-content: ${(props) =>
//         props.windowWidth < 455 ? 'center' : 'space-between'};
//     border-radius: 5px;
//     overflow: hidden;
//     margin: 0 auto;
//     max-width: ${(props) => (props.isCompactVersion ? 'none' : '550px')};
//     margin-right: ${(props) => (props.windowWidth < 569 ? '0px' : '15%')};
//     margin-left: 0px;
//     width: 100%;
// `;

// const ProConSection = styled.div`
//     padding: 20px 10px;
//     margin-top: ${(props) => (props.isCompactVersion ? '-20px' : '-10px')};
//     margin-bottom: -15px;
//     /* background: ${(props) =>
//         props.option === 'pro' ? '#38a16986' : '#e53e3e86'}; */
//     h4 {
//         color: ${(props) => (props.option === 'pro' ? '#38a169' : '#e53e3e')};
//         font-weight: bold;
//         white-space: nowrap;
//         margin-bottom: 10px;
//         margin-top: 0px;
//         font-size: ${(props) =>
//             props.windowWidth < 370
//                 ? '1.07em'
//                 : props.windowWidth < 400
//                 ? '1.1em'
//                 : props.windowWidth < widthPCPBecomeColumn
//                 ? '1.35em'
//                 : props.windowWidth < 630
//                 ? '1.28em'
//                 : props.windowWidth < 670
//                 ? '1.32em'
//                 : props.windowWidth < 750
//                 ? '1.42em'
//                 : '1.48em'};
//     }

//     ul {
//         list-style: none;
//         padding-left: 10px;
//         margin-bottom: 0px;
//     }

//     li {
//         margin-bottom: 10px;
//         position: relative;
//         padding-left: 20px;
//         font-size: ${(props) =>
//             props.windowWidth < 370
//                 ? '0.94em'
//                 : props.windowWidth < 400
//                 ? '0.94em'
//                 : props.windowWidth < widthPCPBecomeColumn
//                 ? '1.09em'
//                 : props.windowWidth < 630
//                 ? '1.08em'
//                 : props.windowWidth < 750
//                 ? '1.1em'
//                 : '1.12em'};
//         /* font-size: 1.15em; */
//         // background: gold;

//         &:last-child {
//             margin-bottom: ${(props) =>
//                 props.isCompactVersion ? '10px' : '20px'};
//         }

//         &:before {
//             content: '';
//             position: absolute;
//             left: 0;
//             top: 12px;
//             height: 6px;
//             width: 6px;
//             background: ${(props) =>
//                 props.option === 'pro' ? '#38a169' : '#e53e3e'};
//             border-radius: 50%;
//         }
//     }
// `;

// const ProConPurchaseLinksContainer = styled.div`
//     display: flex;
//     flex-direction: ${(props) =>
//         props.windowWidth < widthPCPBecomeColumn || !props.isCompactVersion
//             ? 'column'
//             : 'row'};
//     margin-top: ${(props) => (props.isCompactVersion ? '' : '20px')};
// `;

// const PurchaseLinksContainer = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     gap: 7px;
//     align-content: flex-start;
//     border-radius: 5px;
//     margin: 0px -5px 0px 5px;
//     margin-top: ${(props) => (props.isCompactVersion ? '' : '10px')};
// `;

// const PurchaseLinksHeader = styled.h3`
//     margin: 0;
//     flex: 100%;
//     font-family: 'Montserrat', san-serif;
//     font-size: ${(props) =>
//         props.windowWidth < widthPCPBecomeColumn
//             ? '1.28em'
//             : props.windowWidth < 630
//             ? '1.25em'
//             : props.windowWidth < 670
//             ? '1.26em'
//             : props.windowWidth < 750
//             ? '1.3em'
//             : '1.3em'};
//     font-weight: bold;
//     color: #2d3748;
// `;

// const PurchaseLink = styled.a`
//     display: inline-block;
//     padding: ${(props) =>
//         props.windowWidth < widthPCPBecomeColumn
//             ? '7px 8px'
//             : props.windowWidth < 640
//             ? '6px 6px'
//             : props.windowWidth < 750
//             ? '7px 8px'
//             : '8px 15px'};
//     border-radius: 5px;
//     background-color: ${theme.colors.blueButton};
//     text-decoration: none;
//     color: ${theme.colors.buttonText};
//     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
//     transition: all 0.3s ease 0s;
//     &:hover {
//         background-color: ${theme.colors.blueButtonHover};
//     }
// `;

// const PurchaseLinkText = styled.span`
//     font-size: ${(props) =>
//         props.windowWidth < 750
//             ? props.windowWidth < widthPCPBecomeColumn
//                 ? '1em'
//                 : props.windowWidth * (1 / 705) < 0.92
//                 ? '0.92em'
//                 : props.windowWidth * (1 / 705) + 'em'
//             : '1.04em'};
//     font-weight: 500;
//     line-height: 1;
// `;

export const Separator = styled.div`
    position: ${(props) => (props.isAbsolute ? 'absolute' : '')};
    height: 2px;
    background: linear-gradient(
        90deg,
        rgba(0, 212, 255, 1) 0%,
        rgba(9, 9, 121, 1) 80%,
        rgba(2, 0, 36, 1) 100%
    );
    margin: 30px auto 20px auto;
    width: ${(props) => (props.specifiedWidth ? props.specifiedWidth : '70%')};
    border-radius: 10px;
`;

// const ReviewFormat = () => {
//     const { slug } = useParams();
//     const reviewData = articles.find((article) => article.slug === slug);

//     if (!reviewData) {
//         return <NotFoundPage />;
//     }

//     return <ActualReviewFormat reviewData={reviewData} />;
// };
// const ActualReviewFormat = ({ reviewData }) => {
//     const { isNavbarShown } = useContext(NavbarContext);

//     const [bodyImageLoaded, setBodyImageLoaded] = useState(false);
//     const [tagContainerHeight, setTagContainerHeight] = useState('50px');
//     const [showAllTags, setShowAllTags] = useState(false);
//     const [activeItem, setActiveItem] = useState(null);
//     const [userClickedTOC, setUserClickedTOC] = useState(false);
//     const [isScrollingUp, setIsScrollingUp] = useState(false);
//     const [isLoaded, setIsLoaded] = useState(false);

//     const urlRef = useRef(window.location.href);
//     const titleRef = useRef(null);
//     const titleWrapperRef = useRef(null);
//     const itemRefs = useRef([]);

//     const navigate = useNavigate();
//     const location = useLocation();
//     const [windowWidth, windowHeight] = useWindowSize();
//     const [titleSizeReady, setTitleSizeReady] = useState(
//         (windowWidth > 800 && !reviewData.title > 40) ||
//             isLocalStorageItemAvailable(
//                 `calculatedTitleSize-${reviewData.id}-ReviewFormat`
//             )
//             ? true
//             : false
//     );

//     const [videoEmbed] = useYouTubeEmbed(reviewData.videoId, 40);

//     let navbarHeight = parseInt(
//         getNavbarHeight(windowHeight, mobileVersion(windowWidth, 'isMobile'))
//     );

//     const calculatedTitleSize = useFontSize(
//         'reviewFormat',
//         reviewData.id + '-ReviewFormat',
//         reviewData.title,
//         40, // maxFontSize
//         27, // minFontSize
//         true, // isFeatured
//         titleRef,
//         titleWrapperRef,
//         windowWidth
//     );

//     useEffect(() => {
//         setTimeout(() => {
//             setTitleSizeReady(true);
//         }, 4);
//     }, []);

//     useEffect(() => {
//         setTimeout(() => {
//             setIsLoaded(true);
//         }, 80);
//     }, []);

//     const handleTagClick = (tag) => {
//         navigate(`/search?query=${tag}`);
//     };

//     const handleShowMoreClick = () => {
//         const tagHeight = 40; // approx height of a tag
//         const tagContainerPadding = 10; // approx padding of TagContainer
//         const rows = Math.ceil(reviewData.tags.length / 4); // number of rows needed, adjust the divider as per your design
//         const newHeight = tagHeight * rows + tagContainerPadding;

//         setTagContainerHeight(showAllTags ? '50px' : `${newHeight}px`);
//         setShowAllTags(!showAllTags);
//     };

//     const displayedTags = showAllTags
//         ? reviewData.tags
//         : reviewData.tags.slice(0, 4);

//     const handleAuthorClick = (author) => {
//         navigate(`/search?query=${author}`);
//     };

//     const handleTocClick = (index) => {
//         if (itemRefs.current[index]) {
//             const currentScrollPosition = window.pageYOffset;
//             const topPosition =
//                 itemRefs.current[index].getBoundingClientRect().top;
//             const targetPosition = topPosition + window.pageYOffset - 10;
//             let finalPosition;

//             // If we're currently below the target section (scrolling upwards), subtract the navbar height.
//             if (currentScrollPosition > targetPosition) {
//                 finalPosition = targetPosition - navbarHeight;
//                 setIsScrollingUp(true); // Set isScrollingUp to true if we are scrolling upwards
//             } else {
//                 // If we're currently above the target section (scrolling downwards), leave it as is.
//                 finalPosition = targetPosition;
//             }

//             window.scroll({
//                 top: finalPosition,
//                 behavior: 'smooth',
//             });
//             setActiveItem(index);
//             setUserClickedTOC(true);
//         }
//     };

//     useEffect(() => {
//         const handleUserScrollOrDrag = () => {
//             setUserClickedTOC(false); // Reset userClickedTOC when the user starts scrolling or dragging
//         };

//         // Add the event listeners when the component mounts
//         window.addEventListener('wheel', handleUserScrollOrDrag, {
//             passive: true,
//         });
//         window.addEventListener('touchstart', handleUserScrollOrDrag, {
//             passive: true,
//         });
//         window.addEventListener('keydown', handleUserScrollOrDrag, {
//             passive: true,
//         });
//         window.addEventListener('mousedown', handleUserScrollOrDrag, {
//             passive: true,
//         });

//         // Remove the event listeners when the component unmounts
//         return () => {
//             window.removeEventListener('wheel', handleUserScrollOrDrag);
//             window.removeEventListener('touchstart', handleUserScrollOrDrag);
//             window.removeEventListener('keydown', handleUserScrollOrDrag);
//             window.removeEventListener('mousedown', handleUserScrollOrDrag);
//         };
//     }, []);

//     useEffect(() => {
//         const handleScroll = () => {
//             let currentScrollY = window.pageYOffset;
//             if (isScrollingUp) {
//                 currentScrollY += navbarHeight;
//             }

//             const newActiveItem = reviewData.reviewItems?.findIndex(
//                 (_, index) => {
//                     const ref = itemRefs.current[index];
//                     if (ref) {
//                         const { top, height } = ref.getBoundingClientRect();
//                         const start = top + currentScrollY - 10;
//                         const end = start + height;
//                         return start <= currentScrollY && end > currentScrollY;
//                     } else {
//                         return false;
//                     }
//                 }
//             );

//             // Only update the active item if the user hasn't manually selected an item or if we've scrolled past the currently selected item.
//             if (!userClickedTOC) {
//                 setActiveItem(newActiveItem);
//                 setUserClickedTOC(false);
//             }
//             setIsScrollingUp(false); // Reset isScrollingUp to false after the scroll event
//         };

//         window.addEventListener('scroll', handleScroll, { passive: true });
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, [
//         reviewData.reviewItems,
//         activeItem,
//         userClickedTOC,
//         navbarHeight,
//         isScrollingUp,
//     ]);

//     const ReviewItemTitleMemo = memo(ReviewItemTitle);
//     const ReviewHighlightMemo = memo(ReviewHighlight);
//     const ReviewItemDescriptionMemo = memo(ReviewItemDescription);
//     const ProConSectionMemo = memo(ProConSection);

//     const Item = ({ item }, ref) => {
//         // Check if the item is a 'comparedItems' group or an 'Introduction' or 'Verdict' section
//         if (item.comparedItems) {
//             return (
//                 <div ref={ref}>
//                     {item.comparedItems.map((comparedItem, index) => {
//                         const isCompactVersion =
//                             windowWidth > 732 &&
//                             (reviewData.isReviewCompactVersion ||
//                                 comparedItem.isCompactVersion);
//                         const isDescriptionShort =
//                             comparedItem.isCompactVersion &&
//                             comparedItem.shortDescription.length < 560;

//                         return (
//                             <ReviewItemContainer
//                                 key={index}
//                                 isCompactVersion={isCompactVersion}
//                             >
//                                 <ReviewItemDescriptionContainer
//                                     key={index}
//                                     isCompactVersion={isCompactVersion}
//                                 >
//                                     {isCompactVersion ? (
//                                         <>
//                                             <ReviewItemTitleMemo
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                                 windowWidth={windowWidth}
//                                                 highlight={
//                                                     comparedItem.highlight
//                                                 }
//                                                 title={comparedItem.brand}
//                                             >
//                                                 {comparedItem.brand}
//                                             </ReviewItemTitleMemo>

//                                             <ReviewHighlightMemo
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                                 highlight={
//                                                     comparedItem.highlight
//                                                 }
//                                                 title={comparedItem.brand}
//                                                 windowWidth={windowWidth}
//                                             >
//                                                 {comparedItem.highlight}
//                                             </ReviewHighlightMemo>
//                                         </>
//                                     ) : (
//                                         <TitleHighlightContainer
//                                             key={index}
//                                             isCompactVersion={isCompactVersion}
//                                             windowWidth={windowWidth}
//                                         >
//                                             <ReviewItemTitleMemo
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                                 windowWidth={windowWidth}
//                                                 highlight={
//                                                     comparedItem.highlight
//                                                 }
//                                                 title={comparedItem.brand}
//                                             >
//                                                 {comparedItem.brand}
//                                             </ReviewItemTitleMemo>

//                                             <ReviewHighlightMemo
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                                 highlight={
//                                                     comparedItem.highlight
//                                                 }
//                                                 title={comparedItem.brand}
//                                                 windowWidth={windowWidth}
//                                             >
//                                                 {comparedItem.highlight}
//                                             </ReviewHighlightMemo>
//                                         </TitleHighlightContainer>
//                                     )}

//                                     {comparedItem.image && (
//                                         <ReviewItemDescriptionMemo
//                                             isCompactVersion={isCompactVersion}
//                                             windowWidth={windowWidth}
//                                         >
//                                             <div className='image-container'>
//                                                 <img
//                                                     src={comparedItem.image}
//                                                     alt={
//                                                         comparedItem.imageAltText
//                                                     }
//                                                 />
//                                                 <div className='image-caption'>
//                                                     {comparedItem.imageCaption}
//                                                 </div>
//                                             </div>
//                                         </ReviewItemDescriptionMemo>
//                                     )}
//                                     <ReviewItemDescriptionMemo
//                                         windowWidth={windowWidth}
//                                         isCompactVersion={isCompactVersion}
//                                         dangerouslySetInnerHTML={{
//                                             __html: DOMPurify.sanitize(
//                                                 isCompactVersion
//                                                     ? comparedItem.shortDescription
//                                                     : comparedItem.description
//                                             ),
//                                         }}
//                                         isDescriptionShort={isDescriptionShort}
//                                     />
//                                 </ReviewItemDescriptionContainer>
//                                 <ProConPurchaseLinksContainer
//                                     isCompactVersion={isCompactVersion}
//                                     windowWidth={windowWidth}
//                                 >
//                                     <ProConOverContainer
//                                         isCompactVersion={isCompactVersion}
//                                     >
//                                         <ProConContainer
//                                             windowWidth={windowWidth}
//                                             isCompactVersion={isCompactVersion}
//                                         >
//                                             <ProConSectionMemo
//                                                 option='pro'
//                                                 windowWidth={windowWidth}
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                             >
//                                                 <h4
//                                                     style={{
//                                                         marginBottom: -10,
//                                                         fontFamily:
//                                                             'Montserrat, sans-serif',
//                                                         fontWeight: 700,
//                                                     }}
//                                                 >
//                                                     What we like
//                                                 </h4>
//                                                 <ul>
//                                                     {comparedItem.pros.map(
//                                                         (pro, i) => (
//                                                             <li key={i}>
//                                                                 {pro}
//                                                             </li>
//                                                         )
//                                                     )}
//                                                 </ul>
//                                             </ProConSectionMemo>
//                                             <ProConSectionMemo
//                                                 option='con'
//                                                 windowWidth={windowWidth}
//                                                 isCompactVersion={
//                                                     isCompactVersion
//                                                 }
//                                             >
//                                                 <h4
//                                                     style={{
//                                                         marginBottom: -10,
//                                                         fontFamily:
//                                                             'Montserrat, sans-serif',
//                                                         fontWeight: 700,
//                                                     }}
//                                                 >
//                                                     What to consider
//                                                 </h4>
//                                                 <ul>
//                                                     {comparedItem.cons.map(
//                                                         (con, i) => (
//                                                             <li key={i}>
//                                                                 {con}
//                                                             </li>
//                                                         )
//                                                     )}
//                                                 </ul>
//                                             </ProConSectionMemo>
//                                         </ProConContainer>
//                                     </ProConOverContainer>

//                                     <PurchaseLinksContainer
//                                         isCompactVersion={isCompactVersion}
//                                     >
//                                         <PurchaseLinksHeader
//                                             windowWidth={windowWidth}
//                                         >
//                                             Buy it on:
//                                         </PurchaseLinksHeader>
//                                         {comparedItem.purchaseLinks.map(
//                                             (link, i) => (
//                                                 <PurchaseLink
//                                                     key={i}
//                                                     href={link.url}
//                                                     target='_blank'
//                                                     rel='noopener noreferrer'
//                                                     isCompactVersion={
//                                                         isCompactVersion
//                                                     }
//                                                     windowWidth={windowWidth}
//                                                 >
//                                                     <PurchaseLinkText
//                                                         windowWidth={
//                                                             windowWidth
//                                                         }
//                                                     >
//                                                         {link.name}
//                                                     </PurchaseLinkText>
//                                                 </PurchaseLink>
//                                             )
//                                         )}
//                                     </PurchaseLinksContainer>
//                                 </ProConPurchaseLinksContainer>
//                             </ReviewItemContainer>
//                         );
//                     })}
//                 </div>
//             );
//         } else {
//             // For 'Introduction' and 'Verdict ' sections
//             return (
//                 <ReviewItemDescriptionMemo
//                     windowWidth={windowWidth}
//                     ref={ref}
//                     dangerouslySetInnerHTML={{
//                         __html: DOMPurify.sanitize(item.content),
//                     }}
//                 />
//             );
//         }
//     };

//     const ForwardedItem = React.forwardRef(Item);

//     return (
//         <Container windowHeight={windowHeight}>
//             <Helmet>
//                 <title>{`${reviewData.title} | Infoblis`}</title>
//                 <meta
//                     name='description'
//                     content={reviewData.description || reviewData.excerpt}
//                 />
//                 <link
//                     rel='canonical'
//                     href={`https://infoblis.com${location.pathname}`}
//                 />
//                 <meta name='keywords' content={reviewData.tags.join(', ')} />
//                 <meta property='og:type' content='article' />
//                 <meta property='og:title' content={reviewData.title} />
//                 <meta
//                     property='og:description'
//                     content={reviewData.description || reviewData.excerpt}
//                 />
//                 <meta property='og:image' content={reviewData.image} />
//                 <meta
//                     property='og:url'
//                     content={`https://infoblis.com${location.pathname}`}
//                 />
//                 <meta property='og:site_name' content='Infoblis' />
//                 <meta name='twitter:card' content='summary_large_image' />
//                 <meta name='twitter:site' content={helmet.TwitterHandle} />
//                 {/* replace with your actual Twitter handle */}
//                 <meta name='twitter:title' content={reviewData.title} />
//                 <meta
//                     name='twitter:description'
//                     content={reviewData.description || reviewData.excerpt}
//                 />
//                 <meta name='twitter:image' content={reviewData.image} />
//                 <script type='application/ld+json'>
//                     {(() => {
//                         let schema = {
//                             '@context': 'https://schema.org',
//                             '@type': reviewData.typeSchema,
//                             mainEntityOfPage: {
//                                 '@type': 'WebPage',
//                                 '@id': `https://infoblis.com${location.pathname}`,
//                             },
//                             headline: reviewData.title,
//                             description:
//                                 reviewData.description || reviewData.excerpt,
//                             datePublished: convertDateStringToProperFormat(
//                                 reviewData.datePublished
//                             ),
//                             author: {
//                                 '@type': 'Person',
//                                 name: reviewData.author,
//                             },
//                             image: reviewData.image,
//                             publisher: {
//                                 '@type': 'Organization',
//                                 name: 'Infoblis',
//                                 logo: {
//                                     '@type': 'ImageObject',
//                                     url: helmet.LogoUrl,
//                                 },
//                             },
//                         };

//                         if (reviewData.updatedDate) {
//                             schema.dateModified =
//                                 convertDateStringToProperFormat(
//                                     reviewData.updatedDate
//                                 );
//                         }

//                         return JSON.stringify(schema);
//                     })()}
//                 </script>
//             </Helmet>

//             {windowWidth > 1206 && (
//                 <Sticky>
//                     <TableOfContentsContainer
//                         navbarHeight={navbarHeight}
//                         isNavbarShown={isNavbarShown}
//                         windowWidth={windowWidth}
//                     >
//                         <h4 style={{ marginTop: 8, marginBottom: 8 }}>
//                             Table of Contents:
//                         </h4>
//                         {reviewData.reviewItems?.map((item, index) => (
//                             <TocItem
//                                 key={index}
//                                 onClick={() => handleTocClick(index)}
//                                 style={{
//                                     fontWeight:
//                                         activeItem === index
//                                             ? 'bold'
//                                             : 'normal',
//                                 }}
//                             >
//                                 {item.name}
//                             </TocItem>
//                         ))}
//                     </TableOfContentsContainer>
//                 </Sticky>
//             )}
//             {!titleSizeReady && (
//                 <ArticlePlaceholder windowHeight={windowHeight} />
//             )}
//             <TitleWrapper ref={titleWrapperRef} navbarHeight={navbarHeight}>
//                 <Header
//                     ref={titleRef}
//                     calculatedTitleSize={calculatedTitleSize}
//                     windowWidth={windowWidth}
//                     titleSizeReady={titleSizeReady}
//                 >
//                     {reviewData.title}
//                 </Header>
//                 <AuthorDate>
//                     <AuthorName
//                         onClick={() => handleAuthorClick(reviewData.author)}
//                     >
//                         {`${reviewData.author} |\u00A0`}
//                     </AuthorName>

//                     {reviewData.updatedDate
//                         ? 'Updated ' + reviewData.updatedDate
//                         : reviewData.datePublished}
//                     <ShareLinks
//                         url={urlRef.current}
//                         format={reviewData.format}
//                     />
//                 </AuthorDate>
//                 <TagContainer
//                     showAllTags={showAllTags}
//                     style={{ maxHeight: tagContainerHeight }}
//                 >
//                     {displayedTags.map((tag, index) => (
//                         <Tag
//                             key={index}
//                             windowWidth={windowWidth}
//                             onClick={() => handleTagClick(tag)}
//                         >
//                             {tag}
//                         </Tag>
//                     ))}
//                     {reviewData.tags.length > 4 && (
//                         <ArrowTag
//                             onClick={handleShowMoreClick}
//                             style={{ cursor: 'pointer' }}
//                         >
//                             {showAllTags ? (
//                                 <AiOutlineLeft
//                                     style={{
//                                         position: 'relative',
//                                         top: '2px',
//                                         fontSize: '0.8em',
//                                     }}
//                                 />
//                             ) : (
//                                 <AiOutlineRight
//                                     style={{
//                                         position: 'relative',
//                                         top: '2px',
//                                         fontSize: '0.8em',
//                                     }}
//                                 />
//                             )}
//                         </ArrowTag>
//                     )}
//                 </TagContainer>
//             </TitleWrapper>
//             {reviewData.imageAlternative !== 'none' && (
//                 <ImageWrapper>
//                     <ImageContainer
//                         isLoaded={bodyImageLoaded}
//                         windowWidth={windowWidth}
//                     >
//                         <BodyImage
//                             src={
//                                 reviewData.imageAlternative
//                                     ? reviewData.imageAlternative
//                                     : reviewData.image
//                             }
//                             alt={reviewData.imageAltText}
//                             onLoad={() => setBodyImageLoaded(true)}
//                             onError={() => setBodyImageLoaded(true)}
//                             isLoaded={bodyImageLoaded}
//                         />
//                     </ImageContainer>
//                     <ImageCaption>{reviewData.imageCaption}</ImageCaption>
//                 </ImageWrapper>
//             )}
//             {videoEmbed}

//             <article>
//                 <ReviewContainer>
//                     {reviewData.reviewItems?.map((item, index) => (
//                         <React.Fragment key={index}>
//                             <ForwardedItem
//                                 key={index}
//                                 item={item}
//                                 ref={(el) => (itemRefs.current[index] = el)}
//                             />
//                             {item.comparedItems &&
//                                 index < reviewData.reviewItems.length - 2 && (
//                                     <Separator />
//                                 )}
//                         </React.Fragment>
//                     ))}
//                 </ReviewContainer>
//             </article>

//             <DisplayContentContainer>
//                 <DisplayContent
//                     type={reviewData.displayGridType.type}
//                     category={reviewData.displayGridType.category}
//                     articles={articles}
//                     windowWidth={windowWidth}
//                     currentArticleId={reviewData.id}
//                     specialOrigin={'ReviewFormat'}
//                     isLoaded={isLoaded}
//                 />
//             </DisplayContentContainer>
//         </Container>
//     );
// };

// export default ReviewFormat;

// // To make x pop up a bit
// // transition: transform .2s;
// // &:hover {
// // transform: scale(1.005);
// // }
