import { useState, useEffect, useRef } from 'react';

export const useGradualLoad = (content, loadImmediatelyThreshold = 5) => {
    const loadMoreRef = useRef();

    const chunkHeight = 390;

    const loadedChunkIndex = Math.floor(
        Number(localStorage.getItem('loadedContentIndex')) || 0
    );

    const [chunks, setChunks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(loadedChunkIndex);

    useEffect(() => {
        let observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && currentIndex < chunks.length) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                }
            },
            { threshold: 1 }
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => observer && observer.disconnect();
    }, [currentIndex, chunks.length]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (currentIndex < chunks.length) {
                // Add this condition
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }
        }, 140);

        return () => clearInterval(timer);
    }, [currentIndex, chunks.length]);

    useEffect(() => {
        const articleChunks = content
            .split('</p>')
            .slice(0, -1)
            .reduce((chunks, paragraph, index) => {
                if (index % 3 === 0) {
                    chunks.push([paragraph]);
                } else {
                    chunks[chunks.length - 1].push(paragraph);
                }
                return chunks;
            }, []);
        setChunks(articleChunks);

        if (articleChunks.length <= loadImmediatelyThreshold) {
            setCurrentIndex(articleChunks.length);
        }
    }, [content, loadImmediatelyThreshold]);

    useEffect(() => {
        const savedScrollPosition =
            loadedChunkIndex * chunkHeight - chunkHeight;
        window.scrollTo(0, Math.max(0, savedScrollPosition));
    }, [loadedChunkIndex, chunks]);

    useEffect(() => {
        const handleUnload = () => {
            const currentIndex = Math.floor(window.pageYOffset / chunkHeight);
            localStorage.setItem('loadedContentIndex', currentIndex);
        };

        window.addEventListener('beforeunload', handleUnload);
        window.addEventListener('popstate', handleUnload); // handle "back" and "forward" navigation

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
            window.removeEventListener('popstate', handleUnload);
        };
    }, []);

    return { chunks, currentIndex, loadMoreRef };
};
